import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/moment';
import { getFiatHistoryList } from '../../../redux/reducers/transactions/selectors';
import { IGetTransactionHistoryRequestPayload } from '../../../redux/reducers/transactions/types';
import { getFiatHistoryRequest } from '../../../redux/reducers/transactions/reducer';
import { roundingNumber } from '../../../services/utils/roundingNumber';
import { toFixedNumber } from '../../../services/utils/toFixedNumber';
import { statusNames } from '../../TransactionHistory/utils';
import { getUserRequest } from '../../../redux/reducers/auth/reducer';
import { getUserData } from '../../../redux/reducers/auth/selectors';
import DetailedViewFiatForm from '../DetailedViewForm/DetailedViewFiatForm';

export interface IDepositHistoryFiatDetails {
	txId: string | number;
}

interface ICustomInput {
	label: string;
	value: string | number | undefined;
	type: string;
	disabled: boolean;
}

const CustomInput: FC<ICustomInput> = ({ label, value, type, disabled }) => {
	return (
		<div className="input">
			<label>
				<p className="input__name">{label}</p>
				<div className="input-wrapper">
					<input type={type} className="input-item" value={value} disabled={disabled} />
				</div>
			</label>
		</div>
	);
};

const DepositHistoryFiatDetails: FC<IDepositHistoryFiatDetails> = ({ txId }) => {
	const dispatch = useDispatch();
	const currentItemData = useSelector(getFiatHistoryList);
	const userData = useSelector(getUserData);

	useEffect(() => {
		if (txId) {
			const params: IGetTransactionHistoryRequestPayload = {
				apiParams: {
					current_page: 1,
					per_page: 100000000,
					id: Number(txId),
				},
			};
			dispatch(getFiatHistoryRequest(params));
		}
	}, [dispatch, txId]);

	useEffect(() => {
		dispatch(getUserRequest());
	}, [dispatch]);

	const formatDateTime = (sourceDate: string) => {
		const date = new Date(sourceDate);

		const yyyy = date.getFullYear();
		const mo = String(date.getMonth() + 1).padStart(2, '0');
		const dd = String(date.getDate()).padStart(2, '0');
		const hh = String(date.getHours()).padStart(2, '0');
		const mm = String(date.getMinutes()).padStart(2, '0');
		const ss = String(date.getSeconds()).padStart(2, '0');

		return [`${yyyy}-${mo}-${dd}`, `${hh}:${mm}:${ss}`];
	};

	return (
		<>
			{currentItemData?.length ? (
				currentItemData?.map((item: any) => {
					const [date, time] = formatDateTime(item.created_at);
					return (
						<div className="detailed-view" key={item.id}>
							<div className="input-wrap--col-2">
								{item.type === 'deposit' ? (
									<>
										<CustomInput
											label="Timestamp"
											value={`${moment.utc(item.created_at).format('YYYY-MM-DD')} ${moment
												.utc(item.created_at)
												.format('HH:mm:ss')}`}
											type="text"
											disabled
										/>
									</>
								) : (
									<>
										<CustomInput label="Timestamp" value={`${date} ${time}`} type="text" disabled />
									</>
								)}
								<CustomInput
									label="User Email"
									// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
									value={`${userData?.email}`}
									type="text"
									disabled
								/>
								<CustomInput
									label="User Full Name"
									// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
									value={`${userData?.data.first_name} ${userData?.data.last_name}`}
									type="text"
									disabled
								/>
								{userData?.type === 'corporate' && (
									<CustomInput
										label="Company Name"
										// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
										value={`${userData?.data?.company_name}`}
										type="text"
										disabled
									/>
								)}
								<CustomInput
									label="Currency"
									// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
									value={`${item.asset.code.toUpperCase()} ${item.asset.name}`}
									type="text"
									disabled
								/>
								<CustomInput
									label="Gross"
									value={`${roundingNumber(
										item.gross_fee,
										item.asset.code,
										// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
									)} ${item.asset.code.toUpperCase()}`}
									type="text"
									disabled
								/>
								<CustomInput
									label="Fee"
									value={`${roundingNumber(
										item.fee,
										item.asset.code,
										// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
									)} ${item.asset.code.toUpperCase()}`}
									type="text"
									disabled
								/>
								<CustomInput
									label="Net"
									value={`${toFixedNumber(
										item.net_fee,
										item.asset.code,
										true,
										// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
									)} ${item.asset.code.toUpperCase()}`}
									type="text"
									disabled
								/>
								{item?.bank?.account_number && (
									<>
										<CustomInput
											label="Destination"
											// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
											value={`${item?.bank?.bank_name ? item?.bank?.bank_name : '----'} ${
												// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
												item?.bank?.account_number
											}`}
											type="text"
											disabled
										/>
									</>
								)}
								{/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
								<CustomInput label="Tx ID" value={`${item.id}`} type="text" disabled />
								<CustomInput
									label="Status"
									value={`${statusNames[item.status]}`}
									type="text"
									disabled
								/>
								{item?.bank?.account_number && (
									<>
										<CustomInput
											label="Description"
											value={
												item.description ||
												// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
												`${item?.bank?.bank_name ? item?.bank?.bank_name : '-'} ${
													// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
													item?.bank?.account_number ? item?.bank?.account_number : ''
												}`
											}
											type="text"
											disabled
										/>
									</>
								)}
								<CustomInput label="Reference" value={item.reference} type="text" disabled />
							</div>
							<DetailedViewFiatForm item={item} />
						</div>
					);
				})
			) : (
				<div>No data</div>
			)}
		</>
	);
};

export default DepositHistoryFiatDetails;
