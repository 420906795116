import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Dashboard from '../../layouts/Dashboard';
import CreatePasswordForm from './CreatePasswordForm/CreatePasswordForm';
import loginBannerImage from '../../assets/dist/img/login-banner@2x-min.png';

const CreatePassword = () => {
	const [bannerPositionLeft, setBannerPositionLeft] = useState(1);

	useEffect(() => {
		const bodyRect = document.body.getBoundingClientRect();
		const loginBanner = document.getElementById('login_banner');
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const loginBannerRect = loginBanner.getBoundingClientRect();
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const rect = loginBannerRect.left - bodyRect.left;
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		setBannerPositionLeft(rect);
	}, []);

	return (
		<Dashboard>
			<Helmet>
				<body className="login-page" />
				<style type="text/css">{`
					:root {
							--login-banner-left: ${bannerPositionLeft}px;
					}
			`}</style>
			</Helmet>
			<section className="login-section">
				<div className="container">
					<div className="login">
						<div className="login-form__title mb-30">
							<p>Create New Password</p>
							<p className="login-form__subtitle">Please enter a strong and unique password.</p>
						</div>
						<div className="login-form">
							<CreatePasswordForm />
						</div>
					</div>

					<div className="login-banner" id="login_banner">
						<img src={loginBannerImage} alt="" />
						<p className="login-banner__text">
							Multi-currency accounts, foreign exchange and
							<br /> payments that streamline your global operations
						</p>
					</div>
				</div>
			</section>
		</Dashboard>
	);
};

export default CreatePassword;
