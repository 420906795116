import WithdrawFiatPage from 'pages/Auth/WithdrawFiatPage/WithdrawFiatPage';
import UserLogin from 'components/Login/LoginUser';
import HomePage from 'pages/HomePage/HomePage';
import AccountDetails from 'components/AccountDetails/AccountDetails';
import VerificationPage from 'pages/Dashboard/Verification';
import SecuritySettingsPage from 'pages/Dashboard/SecuritySettings';
import TwoFaSettingsPage from 'pages/Dashboard/TwoFaSettings';
import RegistrationPage from 'pages/Dashboard/Registration/Registration';
import RegistrationTwaFaPage from 'pages/Dashboard/RegistrationTwoFa/Registration';
import ConfirmationPage from 'pages/Dashboard/Confirmation/Confirmation';
import WalletsPage from 'pages/Auth/WalletsPage/WalletsPage';
import VerificationDocumentsPage from 'pages/Dashboard/VerificationDocuments';
import PaymentMethodsPage from 'pages/Auth/PaymentMethods/PaymentMethodsPage';
import DepositCryptoPage from 'pages/Auth/DepositCryptoPage/DepositCryptoPage';
import DepositFiatPage from 'pages/Auth/DepositFiatPage/DepositFiatPage';
import TransactionHistoryPage from 'pages/Auth/TransactionHistoryPage/TransactionHistoryPage';
import WithdrawCryptoPage from 'pages/Auth/WithdrawCryptoPage/WithdrawCryptoPage';
import BonusSystem from 'pages/Auth/BonusSystem/BonusSystem';
import ResetPassword from 'components/ResetPassword/ResetPassword';
import CreatePassword from 'components/CreatePassword/CreatePassword';
import TwoFaCreatePage from 'pages/Dashboard/TwoFaCreatePage';
import TermsOfUse from 'pages/TermsOfUsePage/TermsOfUse';
import PrivacyPolicyPage from 'pages/PrivacyPolicyPage/PrivacyPolicyPage';
import ResetTwoFa from 'components/ResetTwoFa/ResetTwoFa';
import DetailedTradePage from 'components/DetailedView/DetailedPage';
import InformationPage from 'pages/Auth/InformationPage/InformationPage';
import { ROUTES } from './constants';
import { IRouteItem } from './types';
import TradePage from '../pages/Dashboard/Trade/Trade';
import DepositsWithdrawalsPage from '../pages/Auth/DepositsWithdrawalsPage/DepositsWithdrawalsPage';
import TransactionsTradesPage from '../pages/Auth/TransactionsTradesPage/TransactionsTradesPage';
import StatementPage from '../pages/Auth/StatementPage/StatementPage';
// ==========================================:

const navList = {
	home: {
		path: ROUTES.INDEX,
		component: HomePage,
	},
	login: {
		path: ROUTES.LOGIN,
		component: UserLogin,
	},
	resetPassword: {
		path: ROUTES.RESET_PASSWORD,
		component: ResetPassword,
	},
	createPassword: {
		path: ROUTES.CREATE_PASSWORD,
		component: CreatePassword,
	},
	registration: {
		path: ROUTES.REGISTRATION.CREATE_ACCOUNT,
		component: RegistrationPage,
	},
	// importantInformation: {
	// 	path: '/registration/important-information',
	// 	component: ImportantInformationPage,
	// },
	// companyInformation: {
	// 	path: '/registration/corporate-information',
	// 	component: CompanyInformationPage,
	// },
	verificationDocuments: {
		path: ROUTES.REGISTRATION.DOCUMENT_VERIFICATION,
		component: VerificationDocumentsPage,
	},
	reserTwoFa: {
		path: ROUTES.RESET_TWOFA,
		component: ResetTwoFa,
	},
	TwoFaCreate: {
		path: ROUTES.CREATE_TWOFA,
		component: TwoFaCreatePage,
	},
	confirmation: {
		path: ROUTES.REGISTRATION.CONFIRMATION,
		component: ConfirmationPage,
	},
	registrationTwoFa: {
		path: ROUTES.REGISTRATION.TWOFA,
		component: RegistrationTwaFaPage,
	},
	trade: {
		path: ROUTES.TRADE,
		component: TradePage,
	},
	detailedTradeView: {
		path: ROUTES.DETAILED_TRADE_VIEW,
		component: DetailedTradePage,
	},
	wallets: {
		path: ROUTES.WALLETS.INDEX,
		component: WalletsPage,
	},
	paymentMethods: {
		path: ROUTES.PAYMENT_METHODS,
		component: PaymentMethodsPage,
	},
	transactionHistory: {
		path: ROUTES.TRANSACTION_HISTORY,
		component: TransactionHistoryPage,
		isNotExact: true,
	},
	/* depositsWithdrawals: {
		path: ROUTES.DEPOSIT_WITHDRAW,
		component: DepositsWithdrawalsPage,
		isNotExact: true,
	}, */
	transactionsTrade: {
		path: ROUTES.TRANSACTION_TRADE,
		component: TransactionsTradesPage,
		isNotExact: true,
	},
	statementHistory: {
		path: ROUTES.STATEMENT_HISTORY,
		component: StatementPage,
		isNotExact: true,
	},
	depositsHistory: {
		path: ROUTES.DEPOSITS_HISTORY,
		component: DepositsWithdrawalsPage,
		isNotExact: true,
	},
	withdrawalsHistory: {
		path: ROUTES.WITHDRAWALS_HISTORY,
		component: DepositsWithdrawalsPage,
		isNotExact: true,
	},
	depositCrypto: {
		path: ROUTES.WALLETS.DEPOSIT_CRYPTO,
		component: DepositCryptoPage,
	},
	depositFiat: {
		path: ROUTES.WALLETS.DEPOSIT_FIAT,
		component: DepositFiatPage,
	},
	withdrawCripto: {
		path: ROUTES.WALLETS.WITHDRAW_CRYPTO,
		component: WithdrawCryptoPage,
	},
	withdrawFiat: {
		path: ROUTES.WALLETS.WITHDRAW_FIAT,
		component: WithdrawFiatPage,
	},
	AccountDetails: {
		path: ROUTES.ACCOUNT_DETAILS,
		component: AccountDetails,
	},
	verification: {
		path: ROUTES.VERIFICATION,
		component: VerificationPage,
	},
	securitySettings: {
		path: ROUTES.SECURITY_SETTINGS.INDEX,
		component: SecuritySettingsPage,
	},
	twoFaSettings: {
		path: ROUTES.SECURITY_SETTINGS.TWOFA,
		component: TwoFaSettingsPage,
	},
	// bonusSystem: {
	// 	path: ROUTES.BONUS_SYSTEM.INDEX,
	// 	component: BonusSystem,
	// },
	termsOfUse: {
		path: ROUTES.TERMS_OF_USE,
		component: TermsOfUse,
	},
	privacyPolicy: {
		path: ROUTES.PRIVACY_POLICY,
		component: PrivacyPolicyPage,
	},
	information: {
		path: ROUTES.INFORMATION,
		component: InformationPage,
	},
};
// ==========================================:
const routesList: Array<IRouteItem> = [];

Object.keys(navList).forEach((item) => routesList.push(navList[item as keyof typeof navList]));

export { navList, routesList };
