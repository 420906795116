import Pagination from 'components/Statement/Pagination/Pagination';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMessagesRequest } from 'redux/reducers/messages/reducer';
import { getMessages } from 'redux/reducers/messages/selectors';
import PerPageSelect from 'ui/PerPageSelect/PerPageSelect';

import Accordion from './Accordion/Accordion';

const InformationTab = () => {
	const dispatch = useDispatch();
	const messages = useSelector(getMessages);

	const [perPage, setPerPage] = useState<number>(10);
	const [currentPage, setCurrentPage] = useState(1);

	const pageCount = messages?.last_page ? messages?.last_page : 1;

	const handleSetPerPage = (value: number | undefined) => {
		if (value) {
			setPerPage(value);
		} else {
			setPerPage(9999);
		}
		setCurrentPage(1);
	};

	useEffect(() => {
		dispatch(getMessagesRequest({ current_page: currentPage, per_page: perPage }));
	}, [currentPage, perPage, dispatch]);

	return (
		<div className="wallet-operations">
			<div className="wallet-content-header wallet-content-header--mb-40">
				<div className="wallet-content-header__title">
					<p>Information</p>
				</div>
			</div>
			<div className="info-section__box">
				<div className="accordion accordion--mt-0">
					{messages?.data && messages.data.length
						? messages.data.map((message) => <Accordion key={message.id} message={message} />)
						: 'No data'}
				</div>
				{messages?.data && messages?.data.length ? (
					<div className="table-footer">
						<div className="select-page">
							<PerPageSelect onChange={handleSetPerPage} />
						</div>

						<div className="pagination-block">
							<Pagination
								pageCount={pageCount}
								currentPage={currentPage}
								onPageChange={setCurrentPage}
							/>
						</div>
					</div>
				) : null}
			</div>
		</div>
	);
};

export default InformationTab;
