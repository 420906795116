import { FC, useEffect, useState } from 'react';

export interface IAccordion {
	id?: number;
	title: string;
	content: string;
	isActive?: boolean;
}

const Accordion: FC<IAccordion> = ({ title, content, isActive }) => {
	const [active, setActive] = useState(false);

	useEffect(() => {
		if (isActive) {
			setActive(isActive);
		}
	}, [isActive]);

	return (
		<div className="accordion__item">
			<div
				className={`accordion__title${active ? ' is-active' : ''}`}
				onClick={() => setActive(!active)}
			>
				{title}
				<span className="accordion__title-arrow" />
			</div>
			{active && (
				<div className="accordion__content" dangerouslySetInnerHTML={{ __html: content }} />
			)}
		</div>
	);
};

export default Accordion;
