import { forwardRef } from 'react';
import { TCustomInputRef, ICustomInputProps } from './types';

export const CustomInput = forwardRef<TCustomInputRef, ICustomInputProps>((props, ref) => {
	// eslint-disable-next-line react/prop-types
	const { onClick, value } = props;
	return (
		<div className="datepicker-input-wrapper" ref={ref} onClick={onClick}>
			<input
				className="input-item input-item--right-icon"
				type="text"
				value={value}
				readOnly
				placeholder="Select"
				id="datepicker"
			/>
			<div className="select__current-date-icon" />
		</div>
	);
});
