import React, { FC } from 'react';
import Popup from 'reactjs-popup';
import { IPopUp } from '../types';

const Disabled2FAPopUp: FC<IPopUp> = ({ open, closeModal }) => {
	const onClose = () => {
		closeModal();
		window.location.replace('/security-settings');
	};

	return (
		<Popup open={open} closeOnDocumentClick onClose={onClose}>
			<div className="popup">
				{/* eslint-disable-next-line jsx-a11y/control-has-associated-label,react/button-has-type */}
				<button className="popup__close-btn" onClick={onClose} />
				<div className="popup-header popup-header--mb-20">
					<p className="popup-header__title">2FA Disabled</p>
				</div>
				<div className="popup-icon popup-icon--type2">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="58"
						height="58"
						viewBox="0 0 58 58"
						fill="none"
					>
						<path
							d="M29.0013 49.8385C40.4596 49.8385 49.8346 40.4635 49.8346 29.0052C49.8346 17.5469 40.4596 8.17188 29.0013 8.17188C17.543 8.17188 8.16797 17.5469 8.16797 29.0052C8.16797 40.4635 17.543 49.8385 29.0013 49.8385Z"
							fill="#007F00"
							stroke="#007F00"
							strokeOpacity="0.06"
							strokeWidth="16"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path d="M21.0469 28.6171L26.3437 33.9139L36.956 23.3203" fill="#007F00" />
						<path
							d="M21.0469 28.6171L26.3437 33.9139L36.956 23.3203"
							stroke="white"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</div>
				<div className="popup-body">
					<div className="popup-text mb-0">
						<p>Thank you.</p>
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default Disabled2FAPopUp;
