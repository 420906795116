/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, DragEvent, useEffect, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { verificationFilesRequest, verificationFilesSave } from 'redux/reducers/settings/reducer';
import { useHistory } from 'react-router';
import uploadImg from 'layouts-elements/DropFileCustom/config/ImageConfig';
import { CloseFileIcon } from 'assets/inline-svg';
import { getSettingsState } from 'redux/reducers/settings/selectors';
import ProgressBar from 'components/ProgressBar/ProgressBar';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { motion } from 'framer-motion/dist/framer-motion';
import PopUp from '../../../layouts-elements/PopUp/PopUp';
import { getUserData } from '../../../redux/reducers/auth/selectors';
import WalletSideBar from '../../Wallets/WalletSideBar/WalletSideBar';
import { dataURLtoBlob, statusNames } from './utiils';

const DropFileInput = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const userData = useSelector(getUserData);
	const [fileList, setFileList] = useState<any>([]);
	const [drag, setDrag] = useState(false);
	const userType = useSelector(getUserData)?.type;

	const { uploadProgress, verificationFilesLoader, files } = useSelector(getSettingsState);
	const handleDragStart = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		setDrag(true);
	};
	const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		setDrag(false);
	};
	const handleOnDrop = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		const updatedList = [...fileList, ...e.dataTransfer.files];
		setFileList(updatedList);
		setDrag(false);
	};

	const onFileDrop = (e: any) => {
		const newFile = e.target.files[0];
		if (newFile) {
			const updatedList = [...fileList, newFile];
			setFileList(updatedList);
		}
	};

	const fileRemove = (file: any) => {
		const updatedList = [...fileList];
		updatedList.splice(fileList?.indexOf(file), 1);
		setFileList(updatedList);
	};
	const submitFile = () => {
		if (!fileList.length) return;
		const fd = new FormData();
		const ins = fileList.length;
		for (let x = 0; x < ins; x += 1) {
			fd.append(`files[${x}]`, fileList[x]);
		}
		dispatch(verificationFilesRequest({ files: fd, history }));
	};

	useEffect(() => {
		if (userData && statusNames[userData?.status_id] === 'Verified') {
			history.push('/account-settings');
		}
	}, [history, userData]);

	const handleStorageFilesSave = useCallback(
		async (fileArr: File[]) => {
			const filesDataArray = await Promise.all(
				fileArr.map(async (file) => {
					return new Promise<any>((resolve) => {
						const reader = new FileReader();
						reader.onload = (event) => {
							if (event.target) {
								const photoData = event.target.result;
								resolve({ name: file.name, type: file.type, data: photoData });
							}
						};
						reader.readAsDataURL(file);
					});
				}),
			);

			if (filesDataArray.length > 0) {
				dispatch(verificationFilesSave(filesDataArray));
			}
		},
		[dispatch],
	);

	useEffect(() => {
		if (files.length > 0) {
			const restoredFiles = files.map((photoData: any) => {
				const blob = dataURLtoBlob(photoData.data);
				return new File([blob], photoData.name, { type: photoData.type });
			});
			setFileList(restoredFiles);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (fileList.length === 0) {
			dispatch(verificationFilesSave([]));
		}
		const saveFilesAndHandle = async () => {
			await handleStorageFilesSave(fileList);
		};
		// eslint-disable-next-line @typescript-eslint/no-floating-promises
		saveFilesAndHandle();
	}, [fileList, handleStorageFilesSave, dispatch]);

	return (
		<>
			<section className="wallet-section">
				<div className="page-wrap">
					<WalletSideBar />
					{!!userData && (
						<motion.div
							className="wallet-content"
							initial={{ opacity: 0 }}
							animate={{ opacity: 1, transition: { duration: 0.4 } }}
							exit={{ opacity: 0, transition: { duration: 0.4 } }}
						>
							{/* {statusNames[userData?.status_id] === 'Verified' && (
								<div className="wallet-content-header">
									<div className="wallet-content-header__title">
										<p>You are already verified</p>
									</div>
								</div>
							)}
							{statusNames[userData?.status_id] === 'Pending' && (
								<div className="wallet-content-header">
									<div className="wallet-content-header__title">
										<p>Your documents are pending</p>
									</div>
								</div>
							)}
							{statusNames[userData?.status_id] === 'Rejected' && (
								<div className="wallet-content-header">
									<div className="wallet-content-header__title">
										<p>Your documents are rejected</p>
									</div>
								</div>
							)} */}

							<>
								<div className="wallet-content-header wallet-content-header--mb-40">
									<div className="wallet-content-header__title">
										<p>Verification</p>
									</div>
									<div className="tabs-buttons--type2">
										<button
											type="button"
											className={userType === 'individual' ? 'active' : 'disabled'}
										>
											Individual Account
										</button>
										<button
											type="button"
											className={userType !== 'individual' ? 'active' : 'disabled'}
										>
											Corporate Account
										</button>
									</div>
								</div>

								<div className="setting-content">
									{statusNames[userData?.status?.name] === 'Unverified' && (
										<>
											<div className="verification-form">
												<div className="verification-item">
													<div className="verification-upload-info">
														<p>Documents to Upload</p>
														{userType === 'individual' ? (
															<ul>
																<li>Photo Identification (Passport or Driver`s License)</li>
																<li>Proof of Address (Utility Bill - Dated Within 3 Months)</li>
																<li>Bank Statement (Dated Within 3 Months)</li>
															</ul>
														) : (
															<ul>
																<li>Corporate Structure Chart</li>
																<li>Certificate of Incorporation</li>
																<li>Register of Directors / Office Holders</li>
																<li>Register of Shareholders</li>
																<li>Bank Statement (Dated Within 3 Months)</li>
															</ul>
														)}
													</div>
													<div className="verification-acount-info">
														<div className="verification-acount-info__heading">Important:</div>
														<ul>
															<li>
																Bank Statements &amp; Utility Bills MUST be dated within 3 months
															</li>
															{userType !== 'individual' && (
																<>
																	<li>
																		Register of Office Holders & Register of Directors MUST be dated
																		with 12 months
																	</li>
																	<li>
																		If there are additional corporate entities within your structure
																		please upload the same documents for each entity.
																	</li>
																</>
															)}
														</ul>
													</div>
												</div>
												<div className="verification-item">
													<>
														<div
															className={`verification-upload${drag ? ' active' : ''}`}
															onDragStart={handleDragStart}
															onDragLeave={handleDragLeave}
															onDragOver={handleDragStart}
															onDrop={handleOnDrop}
														>
															{!verificationFilesLoader ? (
																<label className="verification-upload__button">
																	<input
																		className="hidden"
																		type="file"
																		value=""
																		onChange={onFileDrop}
																	/>
																	<span className="verification-upload__button-content">
																		<svg
																			width="58"
																			height="59"
																			viewBox="0 0 58 59"
																			fill="none"
																			xmlns="http://www.w3.org/2000/svg"
																		>
																			<path
																				d="M29.0013 50.3385C40.4596 50.3385 49.8346 40.9635 49.8346 29.5052C49.8346 18.0469 40.4596 8.67188 29.0013 8.67188C17.543 8.67188 8.16797 18.0469 8.16797 29.5052C8.16797 40.9635 17.543 50.3385 29.0013 50.3385Z"
																				fill="#0071AB"
																				stroke="#007F00"
																				strokeOpacity="0.06"
																				strokeWidth="16"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M31.959 27.4375C34.359 27.6442 35.339 28.8775 35.339 31.5775V31.6642C35.339 34.6442 34.1457 35.8375 31.1657 35.8375H26.8257C23.8457 35.8375 22.6523 34.6442 22.6523 31.6642V31.5775C22.6523 28.8975 23.619 27.6642 25.979 27.4442"
																				stroke="white"
																				strokeWidth="1.5"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M29 22.8359V31.4226"
																				stroke="white"
																				strokeWidth="1.5"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																			<path
																				d="M31.2323 29.9375L28.999 32.1708L26.7656 29.9375"
																				stroke="white"
																				strokeWidth="1.5"
																				strokeLinecap="round"
																				strokeLinejoin="round"
																			/>
																		</svg>
																		<span>
																			<b>Click to upload</b> or drag and drop files
																		</span>
																	</span>
																</label>
															) : (
																<ProgressBar progress={uploadProgress} />
															)}

															{/* <div className="verification-upload__drop">
														<p>Drag & Drop Files</p>
													</div> */}
														</div>

														{fileList.length > 0 && !verificationFilesLoader ? (
															<div className="drop-file-preview">
																{fileList.map((item: any) => (
																	<div key={uuidv4()} className="drop-file-preview__item">
																		<img src={uploadImg[item.type.split('/')[1]]} alt="" />
																		<span className="drop-file-preview__name" title={item.name}>
																			{item.name}
																		</span>
																		<span
																			className="drop-file-preview__item__del"
																			onClick={() => fileRemove(item)}
																		>
																			<CloseFileIcon />
																		</span>
																	</div>
																))}
															</div>
														) : null}
													</>
												</div>
											</div>

											<div className="verification-form__buttons">
												<button
													onClick={() => {
														history.push('/account-settings');
													}}
													type="button"
													className="btn btn-danger"
												>
													Cancel
												</button>
												<button
													type="button"
													className="btn btn-primary"
													onClick={submitFile}
													disabled={
														(userType === 'individual'
															? !(fileList.length === 3)
															: !(fileList.length === 5)) || verificationFilesLoader
													}
												>
													Confirm
												</button>
											</div>
										</>
									)}
									{statusNames[userData?.status?.name] === 'Pending' && (
										<div className="verification-item">
											<div className="verification-pending">
												<p>
													Verification documents were received and now your application is under
													review. Please expect receiving an email with the verification results
													shortly.
												</p>
												<p>
													In the meantime, please do not hesitate to contact us at{' '}
													<a target="blank" href="mailto:support@oneify.io">
														support@oneify.io
													</a>
												</p>
											</div>
										</div>
									)}
								</div>
							</>
						</motion.div>
					)}
				</div>
			</section>

			<PopUp />
		</>
	);
};

export default DropFileInput;
