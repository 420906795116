import React, { useState } from 'react';
import logo from 'assets/dist/img/logo.svg';
import userAvatar from 'assets/dist/img/user-avatar.svg';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { logoutRequest } from 'redux/reducers/auth/reducer';
import { getUserData } from 'redux/reducers/auth/selectors';
import { navList } from '../../../routes/routesList';

const HeaderLogin = () => {
	const dispatch = useDispatch();
	const [profileMenu, setProfileMenu] = useState(false);
	const history = useHistory();
	const user = useSelector(getUserData);
	const isUnverifiedUserStatus =
		user?.status?.name !== 'unverified' && user?.status?.name !== 'pending';
	return (
		/* <>
			<div className="header-left">
				<Link to={isUnverifiedUserStatus ? '/' : 'verification'}>
					{' '}
					<img alt="Logo" src={logo} />
				</Link>
			</div>
			<div className="header-nav">
				<div className={`header-nav-item ${profileMenu ? 'active' : ''}`}>
					<button
						onClick={() => {
							setProfileMenu(!profileMenu);
						}}
						type="button"
						className="header-nav-button"
					>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M12.12 12.78C12.05 12.77 11.96 12.77 11.88 12.78C10.12 12.72 8.71997 11.28 8.71997 9.50998C8.71997 7.69998 10.18 6.22998 12 6.22998C13.81 6.22998 15.28 7.69998 15.28 9.50998C15.27 11.28 13.88 12.72 12.12 12.78Z"
								stroke="#3B97D3"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M18.74 19.3801C16.96 21.0101 14.6 22.0001 12 22.0001C9.40001 22.0001 7.04001 21.0101 5.26001 19.3801C5.36001 18.4401 5.96001 17.5201 7.03001 16.8001C9.77001 14.9801 14.25 14.9801 16.97 16.8001C18.04 17.5201 18.64 18.4401 18.74 19.3801Z"
								stroke="#3B97D3"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
								stroke="#3B97D3"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
						Account
						<svg
							width="10"
							height="10"
							viewBox="0 0 10 10"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path d="M10 3H1H0L5 8L10 3Z" fill="#777E90" />
						</svg>
					</button>
					<div className="header-nav-item__drop">
						{isUnverifiedUserStatus && (
							<Link to="/account-details" className="header-nav-item__drop-item">
								<div className="user-header user-header--centred">
									<div className="user-header__avatar">
										<img src={userAvatar} alt="" />
									</div>
									<div className="user-header__info">
										<div className="user-header__name">
											<p>
												{user?.data?.first_name} {user?.data?.last_name}
											</p>
										</div>
										<div className="user-header__mail">
											<p>{user?.email}</p>
										</div>
									</div>
								</div>
							</Link>
						)}
						<Link to="/verification" className="header-nav-item__drop-item">
							<div className="flex flex-m flex--gap-8">
								<svg
									width="18"
									height="19"
									viewBox="0 0 18 19"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M8.265 15.125H5.625C5.16 15.125 4.7475 15.11 4.38 15.0575C2.4075 14.84 1.875 13.91 1.875 11.375V7.625C1.875 5.09 2.4075 4.16 4.38 3.9425C4.7475 3.89 5.16 3.875 5.625 3.875H8.22"
										stroke="#777E90"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M11.265 3.875H12.375C12.84 3.875 13.2525 3.89 13.62 3.9425C15.5925 4.16 16.125 5.09 16.125 7.625V11.375C16.125 13.91 15.5925 14.84 13.62 15.0575C13.2525 15.11 12.84 15.125 12.375 15.125H11.265"
										stroke="#777E90"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M11.25 2V17"
										stroke="#777E90"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M6 6.875V12.125"
										stroke="#777E90"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
								Verification
							</div>
							<svg
								width="15"
								height="15"
								viewBox="0 0 15 15"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M5.56876 12.45L9.64376 8.37505C10.125 7.8938 10.125 7.1063 9.64376 6.62505L5.56876 2.55005"
									stroke="#777E90"
									strokeWidth="1.5"
									strokeMiterlimit="10"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</Link>
						{isUnverifiedUserStatus && (
							<>
								<Link to="/security-settings" className="header-nav-item__drop-item">
									<div className="flex flex-m flex--gap-8">
										<svg
											width="18"
											height="19"
											viewBox="0 0 18 19"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M9 11.75C10.2426 11.75 11.25 10.7426 11.25 9.5C11.25 8.25736 10.2426 7.25 9 7.25C7.75736 7.25 6.75 8.25736 6.75 9.5C6.75 10.7426 7.75736 11.75 9 11.75Z"
												stroke="#777E90"
												strokeWidth="1.5"
												strokeMiterlimit="10"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
											<path
												d="M1.5 10.1601V8.84007C1.5 8.06007 2.1375 7.41507 2.925 7.41507C4.2825 7.41507 4.8375 6.45507 4.155 5.27757C3.765 4.60257 3.9975 3.72507 4.68 3.33507L5.9775 2.59257C6.57 2.24007 7.335 2.45007 7.6875 3.04257L7.77 3.18507C8.445 4.36257 9.555 4.36257 10.2375 3.18507L10.32 3.04257C10.6725 2.45007 11.4375 2.24007 12.03 2.59257L13.3275 3.33507C14.01 3.72507 14.2425 4.60257 13.8525 5.27757C13.17 6.45507 13.725 7.41507 15.0825 7.41507C15.8625 7.41507 16.5075 8.05257 16.5075 8.84007V10.1601C16.5075 10.9401 15.87 11.5851 15.0825 11.5851C13.725 11.5851 13.17 12.5451 13.8525 13.7226C14.2425 14.4051 14.01 15.2751 13.3275 15.6651L12.03 16.4076C11.4375 16.7601 10.6725 16.5501 10.32 15.9576L10.2375 15.8151C9.5625 14.6376 8.4525 14.6376 7.77 15.8151L7.6875 15.9576C7.335 16.5501 6.57 16.7601 5.9775 16.4076L4.68 15.6651C3.9975 15.2751 3.765 14.3976 4.155 13.7226C4.8375 12.5451 4.2825 11.5851 2.925 11.5851C2.1375 11.5851 1.5 10.9401 1.5 10.1601Z"
												stroke="#777E90"
												strokeWidth="1.5"
												strokeMiterlimit="10"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
										Security Settings
									</div>
									<svg
										width="15"
										height="15"
										viewBox="0 0 15 15"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M5.56876 12.45L9.64376 8.37505C10.125 7.8938 10.125 7.1063 9.64376 6.62505L5.56876 2.55005"
											stroke="#777E90"
											strokeWidth="1.5"
											strokeMiterlimit="10"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								</Link>

								<Link to="/bonus-system" className="header-nav-item__drop-item">
									<div className="flex flex-m flex--gap-8">
										<svg
											width="18"
											height="19"
											viewBox="0 0 18 19"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M2.99167 11.4953L1.85168 10.3553C1.38668 9.89031 1.38668 9.1253 1.85168 8.6603L2.99167 7.52029C3.18667 7.32529 3.34417 6.94279 3.34417 6.67279V5.06027C3.34417 4.40027 3.88418 3.86029 4.54418 3.86029H6.15667C6.42667 3.86029 6.80918 3.70281 7.00418 3.50781L8.14417 2.36779C8.60917 1.90279 9.37418 1.90279 9.83918 2.36779L10.9792 3.50781C11.1742 3.70281 11.5567 3.86029 11.8267 3.86029H13.4392C14.0992 3.86029 14.6392 4.40027 14.6392 5.06027V6.67279C14.6392 6.94279 14.7967 7.32529 14.9917 7.52029L16.1317 8.6603C16.5967 9.1253 16.5967 9.89031 16.1317 10.3553L14.9917 11.4953C14.7967 11.6903 14.6392 12.0728 14.6392 12.3428V13.9553C14.6392 14.6153 14.0992 15.1553 13.4392 15.1553H11.8267C11.5567 15.1553 11.1742 15.3128 10.9792 15.5078L9.83918 16.6478C9.37418 17.1128 8.60917 17.1128 8.14417 16.6478L7.00418 15.5078C6.80918 15.3128 6.42667 15.1553 6.15667 15.1553H4.54418C3.88418 15.1553 3.34417 14.6153 3.34417 13.9553V12.3428C3.34417 12.0653 3.18667 11.6828 2.99167 11.4953Z"
												stroke="#777E90"
												strokeWidth="1.5"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
											<path
												d="M6.75 11.75L11.25 7.25"
												stroke="#777E90"
												strokeWidth="1.5"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
											<path
												d="M10.8709 11.375H10.8776"
												stroke="#777E90"
												strokeWidth="2"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
											<path
												d="M7.12088 7.625H7.12762"
												stroke="#777E90"
												strokeWidth="2"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
										Referrals
									</div>
									<svg
										width="15"
										height="15"
										viewBox="0 0 15 15"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M5.56876 12.45L9.64376 8.37505C10.125 7.8938 10.125 7.1063 9.64376 6.62505L5.56876 2.55005"
											stroke="#777E90"
											strokeWidth="1.5"
											strokeMiterlimit="10"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								</Link>
							</>
						)}
						<button
							onClick={() => {
								dispatch(logoutRequest({ history }));
							}}
							type="button"
							className="button button--type2 button--full-width"
						>
							Log out
						</button>
					</div>
				</div>
			</div>
			<div className="toggler">
				<span />
				<span />
				<span />
			</div>
		</> */
		<>
			<Link to="/" className="header__logo">
				<img alt="Logo" src={logo} />
			</Link>
			<div className="header__buttons">
				<Link to={navList.trade.path} className="btn btn-primary">
					Trade
				</Link>
				<Link to={navList.depositFiat.path} className="btn btn-primary btn-primary--blue300">
					Deposit
				</Link>
				<Link to={navList.withdrawFiat.path} className="btn btn-primary btn-primary--blue100">
					Withdraw
				</Link>
			</div>
		</>
	);
};
export default HeaderLogin;
