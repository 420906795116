import React, { FC, useEffect, useMemo, useState } from 'react';
import Select from '../index';
import { ICurrencySelectCustomList, IWalletAddress, IWalletAddressSelect } from './types';
import { ICurrencySelectItem } from '../CurrencySelect/types';

const CurrencySelectCustomInput: FC<IWalletAddress> = ({ address, nickname }) => (
	<span className="coin coin--type3" style={{ display: address ? '' : 'none' }}>
		<span className="coin__text ">{nickname}</span>
	</span>
);

const CurrencySelectCustomList: FC<ICurrencySelectCustomList> = ({ arr, onChange }) => (
	<ul>
		{arr &&
			arr.map((el) => (
				<li key={el.id}>
					<button type="button" onClick={() => onChange(el)}>
						<span className="coin coin--type3">
							<span className="coin__text ">
								{el.nickname} <br />
							</span>
						</span>
					</button>
				</li>
			))}
	</ul>
);

const WalletAddressSelect: FC<IWalletAddressSelect> = ({
	arr,
	resetCustomSelect,
	setResetCustomSelect,
	onChange,
	...props
}) => {
	const initActiveCurrency = useMemo(() => ({ nickname: '', address: '' }), []);
	const [activeCurrency, setActiveCurrency] = useState<IWalletAddress>(initActiveCurrency);
	const [searchWallet, setSearchWallet] = useState<ICurrencySelectItem[]>([]);

	useEffect(() => {
		if (arr) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			setSearchWallet(arr);
		}
		// console.log(arr);
	}, [arr]);
	// console.log(props?.form?.values?.wallet);
	useEffect(() => {
		setActiveCurrency(initActiveCurrency);
	}, [initActiveCurrency, props.form.values.wallet]);

	useEffect(() => {
		if (resetCustomSelect) {
			setActiveCurrency(initActiveCurrency);
			!!setResetCustomSelect && setResetCustomSelect(false);
		}
	}, [initActiveCurrency, resetCustomSelect, setResetCustomSelect]);

	const onChangeHandler = (el: IWalletAddress) => {
		setActiveCurrency(el);
		!!onChange && onChange(el);
	};

	const customList = {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		list: <CurrencySelectCustomList arr={searchWallet} onChange={onChangeHandler} />,
		activeElement: activeCurrency.address,
	};

	return (
		<Select
			{...props}
			arr={arr}
			searchField
			setSearchWallet={setSearchWallet}
			customInput={<CurrencySelectCustomInput {...activeCurrency} />}
			customList={customList}
		/>
	);
};

export default WalletAddressSelect;
