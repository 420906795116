import { IStoreState } from 'redux/types';
import { IMessageStore } from './types';

export const getMessagesState = (state: IStoreState): IMessageStore => state.messages;

export const getMessagesLoading = (state: IStoreState) => {
	return getMessagesState(state).loading;
};

export const getMessages = (state: IStoreState) => {
	return getMessagesState(state).messages;
};
