import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from '../../types';
import { ITradeStore } from './types';

// ==========================================:
const getAuthState = (state: IStoreState): ITradeStore => state.trade;
export const getTradeData = createSelector([getAuthState], (trade: ITradeStore) => trade);
export const getCanDispatchTimer = createSelector(
	[getAuthState],
	(trade: ITradeStore) => trade.canDispatchTimer,
);

export const getTradeLayers = createSelector([getAuthState], (trade: ITradeStore) => trade.layers);

export const getTradeLayersLog = createSelector(
	[getAuthState],
	(trade: ITradeStore) => trade.layersLog,
);
