import { call, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import axios from 'axios';
import {
	downloadFilesRequest,
	downloadFilesSuccess,
	getMessagesRequest,
	getMessagesSuccess,
} from './reducer';

import {
	IMessageDownloadFilesRequestPayload,
	IMessageRequestPayload,
	IMessageWithPagination,
} from './types';

function* getMessagesWorker({ payload }: PayloadAction<IMessageRequestPayload>) {
	try {
		const data: IMessageWithPagination = yield call(api.messages.getMessages, payload);

		yield put(getMessagesSuccess(data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* downloadFilesWorker({ payload }: PayloadAction<IMessageDownloadFilesRequestPayload>) {
	try {
		const data: string = yield call(api.messages.downloadFiles, payload);
		// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
		const fileName = `${payload.file.split('/').pop()}.${payload.file.split('.').pop()}`;

		const fileBlob = new Blob([data]);
		const fileURL = window.URL.createObjectURL(fileBlob);
		const fileLink = document.createElement('a');

		fileLink.href = fileURL;
		fileLink.setAttribute('download', fileName);
		document.body.appendChild(fileLink);

		fileLink.click();

		document.body.removeChild(fileLink);

		yield put(downloadFilesSuccess());
	} catch (error) {
		if (axios.isAxiosError(error)) {
			// responseErrors(error);
		}
	}
}

export function* messagesSaga() {
	yield takeLatest(getMessagesRequest, getMessagesWorker);
	yield takeLatest(downloadFilesRequest, downloadFilesWorker);
}
