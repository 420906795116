export const downloadFile = (data: string, fileName: string) => {
	const a = document.createElement('a');
	document.body.appendChild(a);
	a.style.display = 'none';

	const blob = new Blob([data], { type: 'octet/stream' });
	const url = window.URL.createObjectURL(blob);
	a.href = url;
	a.download = fileName;
	a.click();
	window.URL.revokeObjectURL(url);
};

export const handleDownloadFile = (response: string, extension: string) => {
	// Data as a Blob
	const blob = response;
	let mimeType = 'text/plain';
	const fileName = `StatementReports.${extension}`;

	// Define MIME  type
	switch (extension) {
		case 'csv':
			mimeType = 'text/csv';
			break;
		case 'xls':
			mimeType = 'application/vnd.ms-excel';
			break;
		case 'xlsx':
			mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
			break;
		default:
			mimeType = 'application/octet-stream';
	}

	const fileBlob = new Blob([blob], { type: mimeType });
	const fileURL = window.URL.createObjectURL(fileBlob);
	const fileLink = document.createElement('a');

	fileLink.href = fileURL;
	fileLink.setAttribute('download', fileName);
	document.body.appendChild(fileLink);

	fileLink.click();

	document.body.removeChild(fileLink);
};
