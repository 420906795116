import { FC, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import Input from 'ui/Formik/Input';
import { loginRequest } from 'redux/reducers/auth/reducer';
import { useHistory } from 'react-router';
import ReCAPTCHA from 'react-google-recaptcha';
import InputTotp from 'ui/Formik/InputTotp';
import { ROUTES } from 'routes/constants';
import { ILoginSubmitValue } from './types';
import { notificationsInfoFields } from '../../services/utils/ipuntFields/ipuntFields';
import { notificationContainer } from '../../services/utils/notificationContainer';
import { notificationsMessagesError } from '../../services/utils/notificationsMessages/notificationsMessagesError';

const LoginInput: FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const siteKey = process.env.REACT_APP_GOOGLE_CAPTCHA;
	const reCaptchaRef = useRef<ReCAPTCHA>(null);
	const [isShowPass, setIsShowPass] = useState(false);
	const [reCaptchaKey, setReCaptchaKey] = useState<string | null>(null);

	const onReCaptchaChange = (key: string | null) => {
		setReCaptchaKey(key);
	};

	const loginSubmit = (obj: ILoginSubmitValue) => {
		const offset = -(new Date().getTimezoneOffset() / 60);
		if (!reCaptchaKey) {
			notificationContainer(notificationsMessagesError.the_captcha_field_is_required, 'error');
		} else {
			dispatch(
				loginRequest({
					data: {
						...obj,
						captcha: reCaptchaKey || '',
						time_zone: offset,
					},
					history,
				}),
			);
		}
	};

	const initialValues = {
		totp: '',
		email: '',
		password: '',
	};
	const validationSchema = yup.object().shape({
		email: yup
			.string()
			.required(notificationsInfoFields.email.required)
			.email(notificationsInfoFields.email.invalid)
			.max(60, notificationsInfoFields.email.maxLength),
		totp: yup
			.string()
			// .required(notificationsInfoFields.totp.required)
			.matches(/\d/, String(notificationsInfoFields.totp.invalid))
			.length(6, notificationsInfoFields.totp.length),
		password: yup
			.string()
			.matches(
				/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[_#?!@$%^&*-]).{8,}$/,
				String(notificationsInfoFields.password.invalid),
			)
			.required(notificationsInfoFields.password.required)
			.max(25, 'Password is too long. Maximum 25 characters.'),
	});

	const handlePassDisplay = (): void => {
		setIsShowPass(!isShowPass);
	};

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(values: ILoginSubmitValue, { setSubmitting }) => {
				loginSubmit(values);
				reCaptchaRef?.current?.reset();
				// resetForm();
				setSubmitting(false);
			}}
			validateOnBlur
			enableReinitialize
		>
			{({ isValid, dirty, isSubmitting }) => (
				<Form className="form">
					<div className="login-form-item">
						<div className="input">
							<p className="input__name">Email</p>
							<div className="input-wrapper">
								<Field type="email" placeholder="Email" name="email" required component={Input} />
							</div>
						</div>
					</div>
					<div className="login-form-item">
						<div className="input">
							<p className="input__name">Password</p>
							<div className="input-wrapper">
								<Field
									type={isShowPass ? 'text' : 'password'}
									placeholder="Password"
									name="password"
									required
									component={Input}
									ariaLabel="show-password"
									showPass={isShowPass}
									setIsShowPass={handlePassDisplay}
									link="/reset-password"
									linkTitle="Forgot Password"
								/>

								<button
									onClick={() => {
										setIsShowPass(!isShowPass);
									}}
									type="button"
									className="show-pass"
								>
									<svg
										style={{ display: isShowPass ? 'none' : 'block' }}
										className="text-type"
										width="22"
										height="22"
										viewBox="0 0 22 22"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M13.3196 8.68125L8.68125 13.3196C8.08542 12.7237 7.71875 11.9079 7.71875 11.0004C7.71875 9.18542 9.18542 7.71875 11.0004 7.71875C11.9079 7.71875 12.7237 8.08542 13.3196 8.68125Z"
											stroke="#212C63"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M16.3358 5.29187C14.7317 4.08187 12.8983 3.42188 11.0008 3.42188C7.765 3.42188 4.74917 5.32854 2.65 8.62854C1.825 9.92104 1.825 12.0935 2.65 13.386C3.37417 14.5227 4.2175 15.5035 5.13417 16.2919"
											stroke="#212C63"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M7.71875 17.899C8.76375 18.339 9.87292 18.5773 11.0004 18.5773C14.2362 18.5773 17.2521 16.6706 19.3512 13.3706C20.1762 12.0781 20.1762 9.90562 19.3512 8.61312C19.0487 8.13646 18.7187 7.68729 18.3796 7.26562"
											stroke="#212C63"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M14.2178 11.6406C13.9795 12.9331 12.9253 13.9873 11.6328 14.2256"
											stroke="#212C63"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M8.67953 13.3203L1.83203 20.1678"
											stroke="#212C63"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M20.1678 1.83594L13.3203 8.68344"
											stroke="#212C63"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>

									<svg
										style={{ display: isShowPass ? 'block' : 'none' }}
										className="password-type"
										width="22"
										height="22"
										viewBox="0 0 22 22"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M14.2821 11.0004C14.2821 12.8154 12.8154 14.2821 11.0004 14.2821C9.18542 14.2821 7.71875 12.8154 7.71875 11.0004C7.71875 9.18542 9.18542 7.71875 11.0004 7.71875C12.8154 7.71875 14.2821 9.18542 14.2821 11.0004Z"
											stroke="#212C63"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M11.0008 18.5849C14.2367 18.5849 17.2525 16.6782 19.3517 13.3782C20.1767 12.0857 20.1767 9.91323 19.3517 8.62073C17.2525 5.32073 14.2367 3.41406 11.0008 3.41406C7.765 3.41406 4.74917 5.32073 2.65 8.62073C1.825 9.91323 1.825 12.0857 2.65 13.3782C4.74917 16.6782 7.765 18.5849 11.0008 18.5849Z"
											stroke="#212C63"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								</button>
							</div>
						</div>
					</div>
					<div className="login-form-item">
						<div className="input">
							<p className="input__name">Authentication Code</p>
							<div className="input-wrapper">
								<Field
									type="text"
									placeholder="Code"
									name="totp"
									required
									component={InputTotp}
									link={ROUTES.RESET_TWOFA}
									linkTitle="Reset 2FA Code"
									className="totp"
								/>
							</div>
						</div>
					</div>
					<div className="login-recaptcha">
						<ReCAPTCHA
							ref={reCaptchaRef}
							onChange={onReCaptchaChange}
							sitekey={siteKey || ''}
							hl="en"
						/>
					</div>

					<div className="login-form-item">
						<button
							// disabled={!(isValid && dirty && reCaptchaKey) || isSubmitting}
							className="btn btn-primary btn--full"
							aria-label="form-submit"
							type="submit"
							disabled={!isValid || !dirty}
							// disabled={isSubmitting}
						>
							Login
						</button>
					</div>
				</Form>
			)}
		</Formik>
	);
};

export default LoginInput;
