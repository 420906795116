import React from 'react';
import { useSelector } from 'react-redux';
import { getUserData } from 'redux/reducers/auth/selectors';
import HeaderLogin from './HeaderLogin/HeaderLogin';
import Header from './Header/Header';

const HeaderTop = () => {
	const login = useSelector(getUserData);

	return (
		<>
			<header className="header">{login === null ? <Header /> : <HeaderLogin />}</header>
		</>
	);
};
export default HeaderTop;
