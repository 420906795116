export const notificationsMessagesPopUp = {
	bankDelete: 'Bank account has not been deleted. Please try again or contact support',
	bankSuccessfullyCreated: (bank: string) =>
		`The new beneficiary "${bank}" was added successfully.`,
	bankSuccessfullyUpdated: (bank: string) =>
		`Your beneficiary account "${bank}" has been updated successfully.`,
	bankSuccessfully: 'Bank account has been added successfully.',
	bankNotCreated: 'Bank account has not been added. Please try again or contact support',
	bankNotUpdate: 'Bank account has not been updated. Please try again or contact support.',
	bonusCompleted: 'Bonus Transfer was completed.',
	walletSuccessfully: (wallet: string) =>
		`Your wallet address "${wallet}" has been updated successfully.`,
	walletUpdated: 'Wallet address has not been updated. Please try again or contact support',
	walletDeleted: 'Wallet address has not been deleted. Please try again or contact support',
	walletSuccessfullyCreated: (wallet: string) =>
		`Your wallet address "${wallet}" was added successfully.`,
	walletSuccessfullyNotCreated:
		'Wallet address has not been created. Please try again or contact support',
};
