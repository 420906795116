import React, { useEffect } from 'react';
import { useHistory } from 'react-router';

const HomePage = () => {
	const history = useHistory();
	useEffect(() => {
		history.push('/wallets');
	});
	return <></>;
};

export default HomePage;
