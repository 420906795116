import React, { FC, useEffect, useMemo, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { IWalletItem } from 'redux/reducers/wallets/types';
import { IWalletsTable } from 'components/Wallets/WalletsTable/type';
import WalletsTableItem from 'components/Wallets/WalletsTable/WalletsTableItem/WalletsTableItem';
import WalletsTableHeader from 'components/Wallets/WalletsTable/WalletsTableHeader/WalletsTableHeader';

const WalletsTable: FC<IWalletsTable> = ({ title, walletsList, itemsPerPage, type }) => {
	const [sortMagnitude, setSortMagnitude] = useState<string>('desc');
	const [sortType, setSortType] = useState<string>('balance_eur');

	const sortedWalletsList = useMemo(() => {
		if (!walletsList) return [];

		if (sortMagnitude === 'asc') {
			return [...walletsList].sort((a, b) => {
				const sortFirstItem =
					sortType === 'total'
						? a.balance + a.frozen_balance
						: a.asset[sortType as keyof typeof a.asset] || Number(a[sortType as keyof typeof a]);

				const sortSecondItem =
					sortType === 'total'
						? b.balance + b.frozen_balance
						: b.asset[sortType as keyof typeof b.asset] || Number(b[sortType as keyof typeof b]);

				return sortFirstItem > sortSecondItem ? 1 : -1;
			});
		}

		if (sortMagnitude === 'desc') {
			return [...walletsList].sort((a, b) => {
				const sortFirstItem =
					sortType === 'total'
						? a.balance + a.frozen_balance
						: a.asset[sortType as keyof typeof a.asset] || Number(a[sortType as keyof typeof a]);

				const sortSecondItem =
					sortType === 'total'
						? b.balance + b.frozen_balance
						: b.asset[sortType as keyof typeof b.asset] || Number(b[sortType as keyof typeof b]);

				return sortFirstItem < sortSecondItem ? 1 : -1;
			});
		}

		return walletsList;
	}, [walletsList, sortMagnitude, sortType]);

	const [currentItems, setCurrentItems] = useState<IWalletItem[] | null>(null);
	const [itemOffset, setItemOffset] = useState(0);
	const [pageCount, setPageCount] = useState(0);

	useEffect(() => {
		if (sortedWalletsList) {
			const sortedArr: any[] = [];
			sortedWalletsList.map((item: any) => {
				if (
					Number(item.balance) === 0 &&
					Number(item.frozen_balance) === 0 &&
					!item.asset.exchangeable &&
					!item.asset.withdrawable &&
					!item.asset.depositable
				) {
					return null;
				}
				sortedArr.push(item);
				return item;
			});
			// console.log(sortedArr);
			const endOffset = itemOffset + itemsPerPage;
			setCurrentItems(sortedArr.slice(itemOffset, endOffset));
			setPageCount(Math.ceil(sortedArr.length / itemsPerPage));
		}
	}, [itemOffset, itemsPerPage, sortedWalletsList]);

	const handlePageClick = ({ selected }: { selected: number }) => {
		if (sortedWalletsList) {
			const newOffset = (selected * itemsPerPage) % sortedWalletsList.length;
			setItemOffset(newOffset);
		}
	};

	// console.log(currentItems);

	return (
		<div>
			<div className="table-title">
				<p>{title}</p>
			</div>
			<div className="table-block table-block--mt-0">
				<div className="table-wrapper">
					<div className="table table--wallet table--fiat-balance">
						<WalletsTableHeader
							type={type}
							sortType={sortType}
							setSortType={setSortType}
							sortMagnitude={sortMagnitude}
							setSortMagnitude={setSortMagnitude}
						/>
						<div className="table-body">
							{!!currentItems?.length &&
								currentItems.map((wallet) => <WalletsTableItem key={wallet.id} {...wallet} />)}
						</div>
					</div>
					{!!walletsList && itemsPerPage < walletsList?.length && (
						<div className="pagination-block">
							<ReactPaginate
								breakLabel="..."
								onPageChange={handlePageClick}
								pageCount={pageCount}
								className="pagination"
								activeClassName="active"
								previousClassName="pagination__arrow pagination__arrow--prev"
								nextClassName="pagination__arrow pagination__arrow--next"
								previousLabel=""
								nextLabel=""
							/>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default WalletsTable;
