import { calculatePercent } from './calculatePercent';

export const calculateFee = (fee_percent: number, fixed_fee: number, amount: number) => {
	const perc = calculatePercent(Number(fee_percent), Number(amount));
	const feeFinal = Number(fixed_fee) + perc;
	return Number(feeFinal);
};

export const calculateFeeReverse = (fee_percent: number, fixed_fee: number, amount: number) => {
	const step1 = 100 - Number(fee_percent);
	const step2 = (Number(amount) + Number(fixed_fee)) / Number(step1);
	const step3 = Number(step2) * Number(fee_percent);
	const stepFinal = Number(step3) + Number(fixed_fee);
	return stepFinal;
};
