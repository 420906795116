import React from 'react';
import { ITableHeader } from './types';

const TableHeader: React.FC<ITableHeader> = ({ children, onUpdate, onDelete, empty }) => {
	return (
		<>
			{!empty && (
				<div className="payment-methods-header">
					{!!children && <div className="payment-methods-header__title">{children}</div>}
					<div className="payment-methods-header__actions">
						{!!onUpdate && (
							// eslint-disable-next-line jsx-a11y/control-has-associated-label
							<button type="button" className="btn btn--icon btn--edit" onClick={onUpdate} />
						)}
						{!!onDelete && (
							// eslint-disable-next-line jsx-a11y/control-has-associated-label
							<button
								type="button"
								className="btn btn--icon-danger btn--delete"
								onClick={onDelete}
							/>
						)}
					</div>
				</div>
			)}
		</>
	);
};

export default TableHeader;
