import React, { FC, useEffect, useState } from 'react';
import { roundingNumber } from 'services/utils/roundingNumber';

import { capitalize, shortNotation, splitString } from 'services/utils/strings';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import { ICryptoTableBodyProps } from '../types';
import { CopyIcon } from '../../../../assets/inline-svg';
import { notificationContainer } from '../../../../services/utils/notificationContainer';
import { getTradeInfo } from '../../../../redux/reducers/transactions/selectors';
import { getWalletsList } from '../../../../redux/reducers/wallets/selectors';
import { getWalletsRequest } from '../../../../redux/reducers/wallets/reducer';

const TableBody: FC<ICryptoTableBodyProps> = ({ itemList, localFilter }) => {
	const dispatch = useDispatch();
	const wallets = useSelector(getWalletsList);
	const { path } = useRouteMatch();

	const [itemFilter, setItemFilter] = useState(itemList);

	useEffect(() => {
		dispatch(getWalletsRequest());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		/* if (localFilter.state === 0) {
			setItemFilter(itemList);
		}
		if (localFilter.state === 1) {
			const max = itemList?.slice().sort((a, b) => {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				return a[localFilter.value] - b[localFilter.value];
			});
			setItemFilter(max);
		}
		if (localFilter.state === 2) {
			const min = itemList?.slice().sort((a, b) => {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				return b[localFilter.value] - a[localFilter.value];
			});
			setItemFilter(min);
		} */
		setItemFilter(itemList);
	}, [itemList, localFilter]);
	const formatDateTime = (sourceDate: string) => {
		const date = new Date(sourceDate);

		const yyyy = date.getFullYear();
		const mo = String(date.getMonth() + 1).padStart(2, '0');
		const dd = String(date.getDate()).padStart(2, '0');
		const hh = String(date.getHours()).padStart(2, '0');
		const mm = String(date.getMinutes()).padStart(2, '0');
		const ss = String(date.getSeconds()).padStart(2, '0');

		return [`${yyyy}-${mo}-${dd}`, `${hh}:${mm}:${ss}`];
	};

	function splitText(text: string) {
		const [oneText, twoText] = text.split('_');
		return [oneText, twoText];
	}

	const searchAssetName = (id: number) => {
		const search = wallets?.find((el) => el.asset.id === id);
		return search?.asset.name;
	};

	// useEffect(() => {
	// 	console.log(wallets);
	// }, [wallets]);

	return (
		<div className="table-body">
			{itemFilter?.length ? (
				itemFilter?.map((item) => {
					const [oneText, twoText] = splitText(item?.pair || 'btc_eur');
					const [date, time] = formatDateTime(item.created_at);
					const idx1 = item.side === 'sell' ? 0 : 1;
					const idx2 = item.side === 'sell' ? 1 : 0;
					const assetFrom = splitString(item.pair, '_')[idx1].toUpperCase();
					const assetTo = splitString(item.pair, '_')[idx2].toUpperCase();
					// const rate = item.side === 'buy' ? 1 / item.rate : item.rate;

					return (
						<div key={item.id} className="tr">
							<div className="td">
								<div className="td-hidden-name">Timestamp</div>
								<div className="td__wrap">
									<div className="td__text">{date}</div>
									<div className="td__more">{time}</div>
								</div>
							</div>
							<div className="td">
								<div className="td-hidden-name">Sell</div>
								<div className="td__wrap">
									<div className="td__text">{capitalize(assetFrom)}</div>
									<div className="td__more">{searchAssetName(item.from_asset_id)}</div>
								</div>
							</div>
							<div className="td">
								<div className="td-hidden-name">Buy</div>
								<div className="td__wrap">
									<div className="td__text">{capitalize(assetTo)}</div>
									<div className="td__more">{searchAssetName(item.to_asset_id)}</div>
								</div>
							</div>
							<div className="td">
								<div className="td-hidden-name">Amount Sold</div>
								<div className="td__wrap">
									<div className="td__text">{roundingNumber(item.quantity, assetFrom)}</div>
									<div className="td__more">{capitalize(assetFrom)}</div>
								</div>
							</div>
							<div className="td">
								<div className="td-hidden-name">Amount Received</div>
								<div className="td__wrap">
									<div className="td__text">{roundingNumber(item.amount, assetTo)}</div>
									<div className="td__more">{capitalize(assetTo)}</div>
								</div>
							</div>
							<div className="td">
								<div className="td-hidden-name">Exchange Rate</div>
								<div className="td__wrap">
									<div className="td__text">{roundingNumber(item?.price_b2c2 || 0, twoText)}</div>
									<div className="td__more">{twoText.toUpperCase()}</div>
								</div>
							</div>
							<div className="td">
								<div className="td-hidden-name">Fee</div>
								<div className="td__wrap">
									<div className="td__text">{roundingNumber(item.fee, assetFrom)}</div>
									<div className="td__more">{capitalize(assetFrom)}</div>
								</div>
							</div>
							<div className="td">
								<div className="td-hidden-name">Tx ID</div>
								<div className="td__wrap">
									<div className="td__text">
										<Link
											to={{
												pathname: `${path}/${item.id}`,
												state: {
													fromTradeHistory: path.includes('history'),
													fromTrade: !path.includes('history'),
													txId: item.id,
												},
											}}
										>
											{item.id}
										</Link>
									</div>
								</div>
							</div>
						</div>
					);
				})
			) : (
				<div className="td text--center">Transactions is empty</div>
			)}
		</div>
	);
};
export default TableBody;
