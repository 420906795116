// export const statusClassNames: Record<TApiTransactionHistoryStatus, string> = {
// 	completed: 'green',
// 	failed: 'red',
// 	pending: 'yellow',
// };

export enum ApiHistoryStatus {
	completed = 'completed',
	failed = 'failed',
	rejected = 'rejected',
	admin_pending = 'admin_pending',
	pending = 'pending',
	pending_signing = 'pending_signing',
	cancelled = 'cancelled',
	broadcasting = 'broadcasting',
}

export const statusNames: Record<string, string> = {
	[ApiHistoryStatus.completed]: 'Completed',
	[ApiHistoryStatus.failed]: 'Failed',
	[ApiHistoryStatus.rejected]: 'Rejected',
	[ApiHistoryStatus.admin_pending]: 'Pending',
	[ApiHistoryStatus.pending]: 'Pending',
	[ApiHistoryStatus.pending_signing]: 'Pending Signing',
	[ApiHistoryStatus.cancelled]: 'Cancelled',
	[ApiHistoryStatus.broadcasting]: 'Broadcasting',
};

export const statusClassNames: Record<string, string> = {
	[ApiHistoryStatus.completed]: 'green',
	[ApiHistoryStatus.failed]: 'red',
	[ApiHistoryStatus.rejected]: 'red',
	[ApiHistoryStatus.admin_pending]: 'yellow',
	[ApiHistoryStatus.pending]: 'yellow',
	[ApiHistoryStatus.pending_signing]: 'yellow',
	[ApiHistoryStatus.cancelled]: 'red',
	[ApiHistoryStatus.broadcasting]: 'blue',
};
