import React from 'react';
import { Link } from 'react-router-dom';
import { navList } from '../../../routes/routesList';

const HistoryHeader = () => {
	// const { url: matchUrl } = useRouteMatch();

	return (
		<div className="wallet-content-header wallet-content-header--mb-40">
			<div className="wallet-content-header__title">
				<p>Trade History</p>
			</div>
			<div className="wallet-content-header__buttons flex flex-e flex-m">
				<Link to={navList.trade.path} className="btn btn-primary">
					Trade
				</Link>
				<Link to={navList.depositFiat.path} className="btn btn-primary btn-primary--blue300">
					Deposit
				</Link>
				<Link to={navList.withdrawFiat.path} className="btn btn-primary btn-primary--blue100">
					Withdraw
				</Link>
			</div>
		</div>
	);
};

export default HistoryHeader;
