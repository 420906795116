import { FC, useState } from 'react';

import { Selected } from 'ui/Selected/Selected';
import CryptocurrencySelect from '../../../ui/CryptocurrencySelect/CryptocurrencySelect';
import { Datepicker } from './Datepicker/Datepicker';
import { selectedItemsStatementArrayType } from '../../../ui/Selected/types';

interface IFiltersProps {
	filters: any;
	onChange: (any: any) => void;
	// eslint-disable-next-line react/require-default-props
	type?: 'crypto' | 'fiat' | null;
	setCurrentPage: (num: number) => void;
	transactionType?: string;
	activeCurrencyParam?: string;
}

const Filters: FC<IFiltersProps> = ({
	filters,
	onChange,
	type,
	setCurrentPage,
	transactionType,
	activeCurrencyParam,
}) => {
	const [resetSelected, setResetSelected] = useState(false);
	const changeFilter = (value: any) => {
		onChange({ ...filters, ...value });
		setCurrentPage(1);
	};

	/// new
	const selectChangeCryptocurrency = (value: string | undefined) => {
		onChange({ ...filters, currency: value });
		setCurrentPage(1);
	};
	// DataPicker
	const [startDate, setStartDate] = useState<Date | null>(null);
	const [endDate, setEndDate] = useState<Date | null>(null);
	const handleSelectDate = (value: [Date | null, Date | null]) => {
		setStartDate(value[0]);
		setEndDate(value[1]);
		onChange({
			...filters,
			date_from: value[0],
			date_to: value[1],
		});
		setCurrentPage(1);
	};

	const handleReset = () => {
		setResetSelected(!resetSelected);
		onChange({
			date_from: undefined,
			date_to: undefined,
			currency: undefined,
			type: undefined,
			tx_id: undefined,
		});
		setStartDate(null);
		setEndDate(null);
		setCurrentPage(1);
	};
	return (
		<div className="table-filter">
			<div className="table-filter-left">
				<Datepicker
					value={[startDate === null ? null : startDate, endDate === null ? null : endDate]}
					onChange={handleSelectDate}
				/>
				<div className="select-block">
					<p className="select-block__name">Currency</p>
					<CryptocurrencySelect
						onSelectChange={selectChangeCryptocurrency}
						resetSelected={resetSelected}
						// type={type}
						disableSearch
						withIcons
						activeValue={activeCurrencyParam}
					/>
				</div>
				{/* <Selected
					resetSelected={resetSelected}
					changeFilter={changeFilter}
					selectedItemsArray={selectedItemsArrayType}
					text="type"
				/> */}
				<Selected
					resetSelected={resetSelected}
					changeFilter={changeFilter}
					selectedItemsArray={selectedItemsStatementArrayType}
					text="type"
				/>
				<div className="input input--size2 input--mb-0">
					<div className="input__name">
						<p>Tx ID</p>
					</div>
					<div className="input-wrapper">
						<input
							onChange={(e) => {
								onChange({ ...filters, tx_id: e.target.value ? e.target.value : undefined });
							}}
							value={filters.tx_id === undefined ? '' : filters.tx_id}
							className="input-item"
							type="text"
							placeholder="Enter Tx ID"
						/>
					</div>
				</div>
			</div>

			<div className="table-filter-right">
				<button type="button" className="btn btn-reset" onClick={handleReset}>
					Reset
				</button>
			</div>
		</div>
	);
};

export default Filters;

Filters.defaultProps = {
	transactionType: undefined,
	activeCurrencyParam: undefined,
};
