import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { Field, Form, Formik } from 'formik';

import Auth from 'layouts/Auth';
import Input from 'ui/Formik/Input';
import { getWalletsCryptoList, getWalletsFiatList } from 'redux/reducers/wallets/selectors';
import { getWalletsRequest } from 'redux/reducers/wallets/reducer';
import WalletSelect from 'ui/Formik/Select/WalletSelect';
import WalletSideBar from 'components/Wallets/WalletSideBar/WalletSideBar';
import { getBankAccounts, getBankAccountsPagesCount } from 'redux/reducers/bankAccounts/selectors';
import {
	changeBankAccountsPage,
	getBankAccountsRequest,
} from 'redux/reducers/bankAccounts/reducer';
import BankSelect from 'ui/Formik/Select/BankSelect';
import ConfirmFiatWithdrawal from 'layouts-elements/PopUp/ConfirmFiatWithdrawal/ConfirmFiatWithdrawal';
import SuccessFiatWithdrawal from 'layouts-elements/PopUp/SuccessFiatWithdrawal/SuccessFiatWithdrawal';
import { getFees, getLimits } from 'redux/reducers/currency/selectors';
import { feeDataRequest, limitsDataRequest } from 'redux/reducers/currency/reducer';
import { IBankAccount } from 'redux/reducers/bankAccounts/types';
import { IWalletAddresses, IWalletItem } from 'redux/reducers/wallets/types';
import { roundingNumber } from 'services/utils/roundingNumber';
import { toFixedNumber } from 'services/utils/toFixedNumber';
import AddBankAccount from 'layouts-elements/PopUp/AddBankAccount/AddBankAccount';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { motion } from 'framer-motion/dist/framer-motion';
import { ILimitItem } from 'redux/reducers/currency/types';

import InputFile from 'ui/Formik/InputFile';
import {
	depositsInitialState,
	feeRequest,
	feeValueRequest,
} from '../../../redux/reducers/deposits/reducer';
import { getFee, getFeeValue } from '../../../redux/reducers/deposits/selectors';
import { notificationsInfoFields } from '../../../services/utils/ipuntFields/ipuntFields';
import { navList } from '../../../routes/routesList';
import WalletAddressSelect from '../../../ui/Formik/Select/WalletAddressSelect';
import AddWalletAddress from '../../../layouts-elements/PopUp/AddWalletAddress/AddWalletAddress';
import ConfirmCryptoWithdrawal from '../../../layouts-elements/PopUp/ConfirmCryptoWithdrawal/ConfirmCryptoWithdrawal';
import SuccessCryptoWithdrawal from '../../../layouts-elements/PopUp/SuccessCryptoWithdrawal/SuccessCryptoWithdrawal';
import { calculatePercent } from '../../../components/Trade/calculatePercent';

const WithdrawFiatPage = () => {
	const dispatch = useDispatch();
	const walletsFiat = useSelector(getWalletsFiatList);
	const walletsCrypto = useSelector(getWalletsCryptoList);
	const bankAccounts = useSelector(getBankAccounts);
	const total = useSelector(getBankAccountsPagesCount);
	const fees = useSelector(getFees);
	const limits = useSelector(getLimits);

	const [selectedWallet, setSelectedWallet] = useState<IWalletItem | null>(null);
	const [selectedBank, setSelectedBank] = useState<IBankAccount | null>(null);
	const [resetCustomSelect, setResetCustomSelect] = useState(false);
	const [resetWalletAddress, setResetWalletAddress] = useState(false);
	const [walletsAddressList, setWalletAddressList] = useState<IWalletAddresses[] | undefined>();
	const [openModalAddAddress, setOpenModalAddAddress] = useState(false);
	const [selectedWalletAddress, setSelectedWalletAddress] = useState<IWalletAddresses | null>(null);
	const [check, setCheck] = useState(false);
	const [openModalConfirm, setOpenModalConfirm] = useState(false);
	const [openModalSuccess, setOpenModalSuccess] = useState(false);
	const [openModalFiatSuccess, setOpenModalFiatSuccess] = useState(false);
	const [openBankAccountModal, setOpenBankAccountModal] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [availableBalance, setAvailableBalance] = useState<string | number | undefined>(0);
	const [code, setCode] = useState<string | undefined>('');
	const [grossMode, setGrossMode] = useState(true);
	const [netMode, setNetMode] = useState(false);
	const [calculatedFee, setCalculatedFee] = useState(0);
	const [calculatedFeeReverse, setCalculatedFeeReverse] = useState(0);
	const [fileList, setFileList] = useState<any>([]);

	const [currencyLimits, setCurrencyLimits] = useState<ILimitItem | null>(null);

	const netFieldRef = useRef<any>();
	const receiveAmountRef = useRef<any>();
	const amountGrossRef = useRef<any>();
	const amountNetRef = useRef<any>();

	const withdrawMin = Number(selectedWallet?.asset.withdraw_min);
	const withdrawMax =
		Number(selectedWallet?.balance) < Number(selectedWallet?.asset.withdraw_max)
			? Number(selectedWallet?.balance)
			: Number(selectedWallet?.asset.withdraw_max);
	// const code = selectedWallet?.asset.code.toUpperCase() || '';
	const assetName = selectedWallet?.asset.name || '';
	const assetType = selectedWallet?.asset.type;

	const closeModalConfirm = useCallback(() => {
		setOpenModalConfirm(false);
	}, []);

	const showModalSuccess = () => {
		setOpenModalSuccess(true);
	};
	const [amountGlobal, setAmountGlobal] = useState(false);
	const getCalcAmount = useCallback(
		(value: string) => {
			console.log('getCalcAmount');
			const numValue = Number(value);
			let percent = 0;
			let receivedFee = 0;
			if (selectedWallet && fees) {
				percent = fees[selectedWallet?.asset.id].withdraw_fee_percent;
				const percentFix = fees[selectedWallet?.asset.id].withdraw_fee_fixed;
				receivedFee = (percent * numValue) / 100;
				if (receivedFee < percentFix) {
					receivedFee = percentFix;
				}
			}
			const fee = receivedFee > 0 ? roundingNumber(receivedFee, 'fiat') : '0';
			const receive =
				numValue - receivedFee > 0 ? roundingNumber(numValue - receivedFee, 'fiat') : '0';
			return { fee, receive };
		},
		[fees, selectedWallet],
	);

	const closeModal = () => setOpenModal(false);

	const handleOpenModalSuccess = () => setOpenModalFiatSuccess(true);
	const handleCloseModalSuccess = () => {
		setOpenModalSuccess(false);
		// history.push('/wallets');
	};

	const showModalAddAddress = useCallback(() => {
		if (selectedWallet?.id) setOpenModalAddAddress(true);
	}, [selectedWallet?.id]);

	const closeModalAddAddress = useCallback(() => {
		setOpenModalAddAddress(false);
	}, []);

	const initialValues = {
		currency: '',
		bank: '',
		amount: '',
		net: '',
		wallet: '',
		address: '',
		reference: '',
		instructions: '',
	};

	let validationShape;

	if (selectedWallet?.asset.type === 'fiat' && grossMode) {
		validationShape = yup.object().shape({
			currency: yup.string().required(notificationsInfoFields.currency.required),
			bank: yup.string().required(notificationsInfoFields.bank.required),
			address: yup.string(),
			amount: yup
				.number()
				.required(notificationsInfoFields.amount.required)
				.min(
					Number(currencyLimits?.minimum),
					`${notificationsInfoFields.amount.min} ${Number(currencyLimits?.minimum)}`,
				)
				.max(
					Number(currencyLimits?.maximum),
					`${notificationsInfoFields.amount.max} ${Number(currencyLimits?.maximum)}`,
				)
				.test(
					'is-valid-amount',
					`Amount should not exceed the global limit`,
					function (value, context) {
						const { amount } = context.parent;
						if (availableBalance && amount) {
							if (availableBalance < amount) {
								// eslint-disable-next-line react/no-this-in-sfc
								return this.createError({
									path: context.path,
									// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
									message: `Available balance ${availableBalance}`,
								});
							}
						}
						return true;
					},
				),
			reference: yup.string().max(255, notificationsInfoFields.reference.max),
			instructions: yup.string().max(255, notificationsInfoFields.instructions.max),
		});
	}
	const fee = useSelector(getFee);
	const feeValue = useSelector(getFeeValue);

	if (selectedWallet?.asset.type === 'fiat' && netMode) {
		validationShape = yup.object().shape({
			currency: yup.string().required(notificationsInfoFields.currency.required),
			bank: yup.string().required(notificationsInfoFields.bank.required),
			address: yup.string(),
			net: yup
				.number()
				.required(notificationsInfoFields.amount.required)
				.min(
					Number(currencyLimits?.minimum) -
						(Number(feeValue?.fixed_fee) +
							Number(currencyLimits?.minimum) * (Number(feeValue?.fee_percent) / 100)),
					`${notificationsInfoFields.amount.min} ${
						Number(currencyLimits?.minimum) -
						(Number(feeValue?.fixed_fee) +
							Number(currencyLimits?.minimum) * (Number(feeValue?.fee_percent) / 100))
					}`,
				)
				.max(
					Number(currencyLimits?.maximum) -
						(Number(feeValue?.fixed_fee) +
							Number(currencyLimits?.maximum) * (Number(feeValue?.fee_percent) / 100)),
					`${notificationsInfoFields.amount.max} ${
						Number(currencyLimits?.maximum) -
						(Number(feeValue?.fixed_fee) +
							Number(currencyLimits?.maximum) * (Number(feeValue?.fee_percent) / 100))
					}`,
				)
				.test(
					'is-less-than-available-net',
					`The amount entered exceeds the available balance after fees. Please enter an amount less than or equal to the available balance.`,
					function (value) {
						const availableBalanceWithFees =
							// eslint-disable-next-line @typescript-eslint/ban-ts-comment
							// @ts-ignore
							availableBalance -
							(Number(feeValue?.fixed_fee) +
								// eslint-disable-next-line @typescript-eslint/ban-ts-comment
								// @ts-ignore
								availableBalance * (Number(feeValue?.fee_percent) / 100));
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
						return value <= availableBalanceWithFees;
					},
				),
			reference: yup.string().max(255, notificationsInfoFields.reference.max),
			instructions: yup.string().max(255, notificationsInfoFields.instructions.max),
		});
	}

	if (selectedWallet?.asset.type !== 'fiat' && grossMode) {
		validationShape = yup.object().shape({
			currency: yup.string().required(notificationsInfoFields.wallet.required),
			address: yup.string().required(notificationsInfoFields.address.required),
			amount: yup
				.number()
				.required(notificationsInfoFields.amount.required)
				.min(
					Number(currencyLimits?.minimum),
					`${notificationsInfoFields.amount.min} ${Number(currencyLimits?.minimum)}`,
				)
				.max(
					Number(currencyLimits?.maximum),
					`${notificationsInfoFields.amount.max} ${Number(currencyLimits?.maximum)}`,
				),
			reference: yup.string().max(255, notificationsInfoFields.reference.max),
			instructions: yup.string().max(255, notificationsInfoFields.instructions.max),
		});
	}

	if (selectedWallet?.asset.type !== 'fiat' && netMode) {
		validationShape = yup.object().shape({
			currency: yup.string().required(notificationsInfoFields.wallet.required),
			address: yup.string().required(notificationsInfoFields.address.required),
			net: yup
				.number()
				.required(notificationsInfoFields.amount.required)
				.min(
					Number(currencyLimits?.minimum),
					`${notificationsInfoFields.amount.min} ${Number(currencyLimits?.minimum)}`,
				)
				.max(
					Number(currencyLimits?.maximum),
					`${notificationsInfoFields.amount.max} ${Number(currencyLimits?.maximum)}`,
				),
			reference: yup.string().max(255, notificationsInfoFields.reference.max),
			instructions: yup.string().max(255, notificationsInfoFields.instructions.max),
		});
	}

	const validationSchema = validationShape;

	useEffect(() => {
		dispatch(changeBankAccountsPage({ per_page: total, current_page: 1 }));
		dispatch(getBankAccountsRequest());
	}, [total, dispatch]);

	// useEffect(() => {
	// 	console.log(walletsFiat);
	// }, [walletsFiat]);

	// useEffect(() => {
	// 	console.log(roundingNumber(selectedWallet?.asset.withdraw_max, code));
	// 	console.log(withdrawMax);
	// }, [code, selectedWallet, withdrawMax]);

	useEffect(() => {
		dispatch(getWalletsRequest());
		dispatch(feeDataRequest());
		if (localStorage.asset && walletsFiat) {
			const currentCoin =
				walletsFiat.find(
					(el: IWalletItem) => el.id === Number(JSON.parse(localStorage.asset).asset_id),
				) || null;
			if (currentCoin) {
				setSelectedWallet(currentCoin);
			}
		}
		if (localStorage.asset && walletsCrypto) {
			const currentCrypto =
				walletsCrypto.find(
					(el: IWalletItem) => el.id === Number(JSON.parse(localStorage.asset).asset_id),
				) || null;
			if (currentCrypto) {
				setSelectedWallet(currentCrypto);
			}
		}
		return () => {
			localStorage.removeItem('asset');
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!localStorage.asset && walletsFiat) {
			let currentCoin = walletsFiat.find(
				(el: IWalletItem) => el.asset.code.toLowerCase() === 'eur' && el.asset.withdrawable,
			);
			if (!currentCoin) {
				currentCoin = walletsFiat.find((el: IWalletItem) => el.asset.withdrawable);
			}
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			setSelectedWallet(currentCoin);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [walletsFiat]);

	useEffect(() => {
		if (selectedWallet) {
			setResetWalletAddress(true);
		}
		setAvailableBalance(selectedWallet?.balance);
		setCode(selectedWallet?.asset.code.toUpperCase());

		if (selectedWallet?.asset.id) {
			const payload = {
				asset_id: selectedWallet?.asset.id,
				type: 'withdraw',
				amount: 1,
			};
			dispatch(feeValueRequest(payload));
		}
		// console.log(selectedWallet);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedWallet]);

	useEffect(() => {
		if (localStorage.asset && walletsFiat) {
			const currentCoin =
				walletsFiat.find(
					(el: IWalletItem) => el.id === Number(JSON.parse(localStorage.asset).asset_id),
				) || null;
			if (currentCoin) {
				setSelectedWallet(currentCoin);
			}
		}
		if (localStorage.asset && walletsCrypto) {
			const currentCrypto =
				walletsCrypto.find(
					(el: IWalletItem) => el.id === Number(JSON.parse(localStorage.asset).asset_id),
				) || null;
			if (currentCrypto) {
				setSelectedWallet(currentCrypto);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [walletsFiat, walletsCrypto]);

	const handleAddAccountClick = () => {
		setOpenBankAccountModal(true);
	};

	const closeBankAccountModal = () => {
		setOpenBankAccountModal(!openBankAccountModal);
	};

	useEffect(() => {
		if (selectedWallet) {
			setResetCustomSelect(true);
		}

		if (selectedWallet?.id) {
			const currentWallet = walletsCrypto?.find((el) => el.id === selectedWallet.id);
			const addressList = currentWallet?.address.filter((el) => el.type === 'withdrawal');
			setWalletAddressList(addressList);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedWallet, check]);

	useEffect(() => {
		dispatch(limitsDataRequest());
	}, [dispatch]);

	useEffect(() => {
		if (limits) {
			setCurrencyLimits(limits.filter((item) => item.asset_id === selectedWallet?.asset_id)[0]);
		}
	}, [selectedWallet, limits]);

	// useEffect(() => {
	// 	console.log(feeValue);
	// }, [feeValue]);
	// useEffect(() => {
	// 	console.log(calculatedFee);
	// }, [calculatedFee]);
	// useEffect(() => {
	// 	console.log(calculatedFeeReverse);
	// }, [calculatedFeeReverse]);

	const calculateFee = (fee_percent: number, amount: number, fixed_fee: number) => {
		if (fee_percent !== 0) {
			// const perc = `0.0${fee_percent.toString().replace('.', '')}`;
			const perc = calculatePercent(fee_percent, amount);
			// console.log((Number(amount) - Number(fixed_fee)) * Number(perc) + Number(fixed_fee));
			const feeGross = perc + Number(fixed_fee);
			// const feeGross =
			// 	((Number(amount) - Number(fixed_fee)) / 100) * Number(fee_percent) + Number(fixed_fee);
			setCalculatedFee(Number(feeGross.toFixed(5)));
			return Number(feeGross.toFixed(5));
		}
		const feeGross = Number(fixed_fee);
		setCalculatedFee(Number(feeGross.toFixed(5)));
		return Number(feeGross.toFixed(5));
	};
	const calculateFeeReverse = (fee_percent: number, amount: number, fixed_fee: number) => {
		const step1 = 100 - Number(fee_percent);
		const step2 = (Number(amount) + Number(fixed_fee)) / Number(step1);
		const step3 = Number(step2) * Number(fee_percent);
		const stepFinal = Number(step3) + Number(fixed_fee);
		setCalculatedFeeReverse(stepFinal);
		return stepFinal;
	};

	return (
		<Auth>
			<section className="wallet-section">
				<div className="page-wrap">
					<WalletSideBar />
					<motion.div
						className="wallet-operations"
						initial={{ opacity: 0 }}
						animate={{ opacity: 1, transition: { duration: 0.4 } }}
						exit={{ opacity: 0, transition: { duration: 0.4 } }}
					>
						<div className="wallet-content-header">
							<div className="wallet-content-header__title">
								<p>Withdrawal Instructions</p>
							</div>
							<div className="wallet-content-header__buttons flex flex-e flex-m">
								<Link to={navList.trade.path} className="btn btn-primary">
									Trade
								</Link>
								<Link
									to={navList.depositFiat.path}
									className="btn btn-primary btn-primary--blue300"
								>
									Deposit
								</Link>
								<Link
									to={navList.withdrawFiat.path}
									className="btn btn-primary btn-primary--blue100"
								>
									Withdraw
								</Link>
							</div>
						</div>
						<div className="wallet-operations__form-wrap">
							<Formik
								validationSchema={validationSchema}
								initialValues={initialValues}
								onSubmit={() => {
									selectedWallet?.asset.type === 'fiat'
										? setOpenModal(true)
										: setOpenModalConfirm(true);
								}}
							>
								{({
									values,
									resetForm,
									setFieldTouched,
									touched,
									errors,
									setFieldValue,
									setValues,
									setTouched,
								}) => {
									// const amount = getCalcAmount(values.amount);
									const clearData = () => {
										setResetCustomSelect(true);
										setResetWalletAddress(true);
										setSelectedWallet(null);
										setOpenModalSuccess(false);
										setOpenModalFiatSuccess(false);
										resetForm();
									};

									const closeModalHandler = () => {
										setFieldValue('bank', '');
										setFieldValue('address', '');
										setFieldValue('amount', '');
										setFieldValue('net', '');
										setFieldValue('reference', '');
										setFieldValue('instructions', '');
										setFieldTouched('bank', false);
										setFieldTouched('address', false);
										setFieldTouched('amount', false);
										setFieldTouched('reference', false);
										setFieldTouched('instructions', false);
										setFileList([]);
										closeModal();
										closeModalConfirm();
										handleCloseModalSuccess();
										setResetWalletAddress(true);
										setResetCustomSelect(true);
										setGrossMode(true);
										setNetMode(false);
										// console.log('closeModalHandler');
									};
									const requestFee = () => {
										if (Number(values.amount) !== 0 && !values.net) {
											const payload = {
												asset_id: selectedWallet?.asset.id,
												type: 'withdraw',
												amount: values.amount,
											};
											dispatch(feeRequest(payload));
										}
										if (Number(values.net) !== 0) {
											const payload = {
												asset_id: selectedWallet?.asset.id,
												type: 'withdraw',
												amount: values.net,
											};
											dispatch(feeRequest(payload));
										}
									};
									// requestFee();
									const maxFunc = (
										currencyLimitsMaximum: undefined | number,
										availableBalanceMax: string | number | undefined,
									) => {
										if (currencyLimitsMaximum && availableBalanceMax) {
											if (currencyLimitsMaximum > Number(availableBalanceMax)) {
												return availableBalanceMax;
											}
											return availableBalanceMax;
										}
										return null;
									};
									if (values.amount) {
										calculateFee(
											Number(feeValue?.fee_percent),
											Number(values.amount),
											Number(feeValue?.fixed_fee),
										);
									}
									if (values.net) {
										calculateFeeReverse(
											Number(feeValue?.fee_percent),
											Number(values.net),
											Number(feeValue?.fixed_fee),
										);
									}
									const hasExceededBalance = (availableBalanceHas: number, amountValue: number) => {
										if (amountValue > availableBalanceHas) {
											setAmountGlobal(true);
											return true;
										}
										setAmountGlobal(false);
										return false;
									};
									// eslint-disable-next-line @typescript-eslint/ban-ts-comment
									// @ts-ignore
									const availableBalanceWithFees =
										// eslint-disable-next-line @typescript-eslint/ban-ts-comment
										// @ts-ignore
										availableBalance -
										(Number(feeValue?.fixed_fee) +
											// eslint-disable-next-line @typescript-eslint/ban-ts-comment
											// @ts-ignore
											availableBalance * (Number(feeValue?.fee_percent) / 100));
									return (
										<Form className="form">
											<div className="wallet-operations-item coin__text-normal">
												<div className="wallet-operations-item__title">
													<span>1</span>
													<p>Select Currency</p>
												</div>
												<Field
													type="text"
													placeholder="Select Currency"
													dropdownTitle="Select Currency"
													name="currency"
													required
													component={WalletSelect}
													arr={[
														// eslint-disable-next-line @typescript-eslint/ban-ts-comment
														// @ts-ignore
														// eslint-disable-next-line no-unsafe-optional-chaining
														...walletsFiat?.filter((el) => el.asset.withdrawable),
														// eslint-disable-next-line @typescript-eslint/ban-ts-comment
														// @ts-ignore
														// eslint-disable-next-line no-unsafe-optional-chaining
														...walletsCrypto?.filter((el) => el.asset.withdrawable),
													]}
													onChange={(value: React.SetStateAction<IWalletItem | null>) => {
														setFieldValue('amount', '');
														setFieldValue('net', '');
														setSelectedWallet(value);
														setFieldTouched('amount', false);
														setFieldTouched('net', false);
														dispatch(depositsInitialState());
													}}
													activeValue={selectedWallet || undefined}
													tabs
												/>
											</div>
											<>
												<div
													className="wallet-operations-item wallet-operations-item--border-top"
													style={{
														display: selectedWallet?.asset.type === 'fiat' ? 'block' : 'none',
													}}
												>
													<div className="wallet-operations-item__title">
														<span>2</span>
														<p>Select Beneficiary</p>
													</div>
													<Field
														setTouched={() => setFieldTouched('bank')}
														touched={touched.bank}
														type="text"
														placeholder="Select Beneficiary"
														dropdownTitle="Bank Account"
														name="bank"
														required={selectedWallet?.asset.type === 'fiat'}
														component={BankSelect}
														buttonName="Add Beneficiary"
														buttonClick={handleAddAccountClick}
														arr={bankAccounts}
														onChange={setSelectedBank}
														resetCustomSelect={resetCustomSelect}
														setResetCustomSelect={setResetCustomSelect}
														activeValue={{ value: selectedBank?.bank_name }}
													/>
													{touched.bank && !values.bank && (
														<div className="input__notification input__notification--type2 input__notification--ta-r">
															<p>{errors.bank}</p>
														</div>
													)}
												</div>
												<div
													onClick={() => {
														setCheck(!check);
														dispatch(getWalletsRequest);
													}}
													className="wallet-operations-item wallet-operations-item--border-top"
													style={{
														display: selectedWallet?.asset.type !== 'fiat' ? 'block' : 'none',
													}}
												>
													<div className="wallet-operations-item__title">
														<span>2</span>
														<p>Select Wallet Address</p>
													</div>
													<Field
														setTouched={() => setFieldTouched('address')}
														touched={touched.address}
														type="text"
														name="address"
														required={selectedWallet?.asset.type !== 'fiat'}
														dropdownTitle="Select Wallet Addresses"
														placeholder="Select Wallet Address"
														component={WalletAddressSelect}
														arr={walletsAddressList}
														resetCustomSelect={resetWalletAddress}
														setResetCustomSelect={setResetWalletAddress}
														buttonName="Add Wallet Address"
														buttonClick={showModalAddAddress}
														onChange={setSelectedWalletAddress}
														disabled={!code}
													/>
													{touched.address && !values.bank && (
														<div className="input__notification input__notification--type2 input__notification--ta-r">
															<p>{errors.address}</p>
														</div>
													)}
												</div>
											</>
											{selectedWallet && (
												<>
													<div className="wallet-operations-item wallet-operations-item--border-top amount-input">
														<div className="wallet-operations-item__title">
															<span>3</span>
															<p>Gross Withdrawal Amount</p>
														</div>
														<div
															ref={amountGrossRef}
															style={{ display: netMode ? 'none' : 'block' }}
														>
															<Field
																placeholder={
																	selectedWallet
																		? `Minimum: ${roundingNumber(currencyLimits?.minimum, code)}`
																		: ''
																}
																name="amount"
																type="number"
																required
																disabled={!code}
																component={Input}
																addonRight={code}
																buttonMax={maxFunc(currencyLimits?.maximum, availableBalance)}
																buttonMaxUpdateAmount={() => setFieldTouched('amount', true)}
																onKeyUp={() =>
																	calculateFee(
																		Number(feeValue?.fee_percent),
																		Number(values.amount),
																		Number(feeValue?.fixed_fee),
																	)
																}
																onBlur={() => {
																	setFieldTouched('amount', true);
																}}
															/>
														</div>
														<div
															ref={amountNetRef}
															className="receive-amount"
															style={{ marginBottom: 16, display: grossMode ? 'none' : 'block' }}
														>
															<div
																className="receive-amount__box"
																onClick={() => {
																	amountGrossRef.current.style.display = 'block';
																	amountGrossRef.current.querySelector('input').focus();
																	setGrossMode(true);
																	setNetMode(false);
																	setFieldValue('net', '');
																}}
															>
																<span className="receive-amount__box-text">
																	{values.net ? (
																		<>
																			{/* {feeValue !== null
																				? toFixedNumber(
																						Number(values.net) + Number(calculatedFee),
																						code,
																				  )
																				: null} */}
																			{feeValue !== null
																				? roundingNumber(
																						Number(calculatedFeeReverse) + Number(values.net),
																						code,
																				  )
																				: null}
																		</>
																	) : null}
																</span>
																<span className="receive-amount__box-code">{code}</span>
															</div>
														</div>

														{!!selectedWallet && (
															<div className="input__notification input__notification--type5">
																<p>
																	Available Balance:{' '}
																	<span>
																		{code && toFixedNumber(availableBalance, code, true)}
																		{code && <span>{code.toUpperCase()}</span>}
																	</span>
																</p>
															</div>
														)}
													</div>
													<div className="wallet-operations-item wallet-operations-item--border-top">
														<div className="receive">
															<div className="wallet-operations-item__title">
																<span>4</span>
																<p>Net Withdrawal Amount</p>
															</div>

															<div className="receive-amount">
																<div
																	id="net"
																	ref={netFieldRef}
																	style={{ display: grossMode ? 'none' : 'block' }}
																>
																	<Field
																		name="net"
																		type="number"
																		component={Input}
																		addonRight={code}
																		onBlur={() => {
																			setFieldTouched('net', true);
																		}}
																		onKeyUp={() => {
																			calculateFeeReverse(
																				Number(feeValue?.fee_percent),
																				Number(values.net),
																				Number(feeValue?.fixed_fee),
																			);
																		}}
																	/>
																</div>

																<div
																	ref={receiveAmountRef}
																	className="receive-amount__box"
																	style={{ display: netMode ? 'none' : 'flex' }}
																	onClick={() => {
																		netFieldRef.current.style.display = 'block';
																		netFieldRef.current.querySelector('input').focus();
																		setGrossMode(false);
																		setNetMode(true);
																		setFieldValue('amount', '');
																	}}
																>
																	{values.amount ? (
																		<>
																			<span className="receive-amount__box-text">
																				{feeValue !== null ? (
																					<>
																						{toFixedNumber(
																							Number(values.amount) - Number(calculatedFee),
																							code,
																							true,
																						)}
																					</>
																				) : null}
																			</span>
																			<span className="receive-amount__box-code">{code}</span>
																		</>
																	) : (
																		<>
																			<span className="receive-amount__box-text">{0}</span>
																			<span className="receive-amount__box-code">{code}</span>
																		</>
																	)}
																</div>
																{Number(values.amount) - Number(calculatedFee) <= 0 &&
																values.amount !== '' ? (
																	<div className="input-notify">
																		<div className="input__notification input__notification--type2 input__notification--ta-r">
																			<p>Net withdrawal should be more than 0.</p>
																		</div>
																	</div>
																) : null}
																<div className="receive-amount__fees">
																	<div className="receive-amount__fees-item">
																		<div className="receive-amount__fees-text">Withdrawal Fee:</div>
																		<div className="receive-amount__fees-price">
																			{values.amount || values.net ? (
																				<>
																					{values.amount
																						? roundingNumber(calculatedFee, code)
																						: roundingNumber(calculatedFeeReverse, code)}
																					{/* {values.amount ? calculatedFee : calculatedFeeReverse} */}
																					<span>{code && code.toUpperCase()}</span>
																				</>
																			) : (
																				<>
																					{0}
																					<span>{code && code.toUpperCase()}</span>
																				</>
																			)}
																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div className="wallet-operations-item wallet-operations-item--border-top">
															<div className="wallet-operations-item__title">
																<span>5</span>
																<p>Reference</p>
															</div>
															<Field
																type="text"
																placeholder="Enter Reference"
																name="reference"
																required
																component={Input}
																className="text-field"
															/>
														</div>
														<div className="wallet-operations-item wallet-operations-item--border-top">
															<div className="wallet-operations-item__title">
																<span>6</span>
																<p>Notes / Instructions</p>
															</div>

															<Field
																type="textarea"
																placeholder="Enter your text here"
																name="instructions"
																required
																component={Input}
																className="text-field"
															/>
														</div>
														<div className="wallet-operations-item wallet-operations-item--border-top amount-input">
															<div className="wallet-operations-item__title">
																<span>7</span>
																<p>Supporting Information</p>
															</div>
															<InputFile fileList={fileList} setFileList={setFileList} />
														</div>
														<button
															disabled={
																Number(values.amount) > 0
																	? Number(
																			roundingNumber(
																				Number(values.amount) -
																					(Number(values.amount) * Number(feeValue?.fee_percent)) /
																						100 -
																					Number(feeValue?.fixed_fee),
																				code,
																			),
																	  ) <= 0 ||
																	  (values.amount !== '' &&
																			hasExceededBalance(
																				Number(availableBalance),
																				Number(values.amount),
																			)) ||
																	  // Новая логика: кнопка отключается, если введенная сумма превышает доступный баланс с учетом комиссий
																	  Number(values.net) > availableBalanceWithFees
																	: Number(roundingNumber(Number(values.net), code)) <= 0
															}
															type="submit"
															className="btn btn-primary btn--full"
														>
															Confirm Withdrawal
														</button>
													</div>
												</>
											)}
											{openBankAccountModal && (
												<AddBankAccount
													open={openBankAccountModal}
													closeModal={closeBankAccountModal}
												/>
											)}

											{!!code && (
												<>
													{grossMode ? (
														<ConfirmFiatWithdrawal
															open={openModal}
															closeModal={closeModal}
															openSuccess={handleOpenModalSuccess}
															indicatedAmount={values.amount}
															bankNickname={values.bank}
															code={code}
															assetName={assetName}
															clearParentForm={closeModalHandler}
															asset_id={selectedWallet?.asset.id}
															bank_id={selectedBank?.id}
															walletId={selectedWallet?.id}
															amount={values.amount}
															fee={Number(calculatedFee)}
															withdrawMax={roundingNumber(currencyLimits?.maximum, code)}
															reference={values.reference}
															clientNote={values.instructions}
															files={fileList}
														/>
													) : (
														<ConfirmFiatWithdrawal
															open={openModal}
															closeModal={closeModal}
															openSuccess={handleOpenModalSuccess}
															indicatedAmount={Number(values.net) + Number(calculatedFeeReverse)}
															bankNickname={values.bank}
															code={code}
															assetName={assetName}
															clearParentForm={closeModalHandler}
															asset_id={selectedWallet?.asset.id}
															bank_id={selectedBank?.id}
															walletId={selectedWallet?.id}
															amount={Number(values.net) + Number(calculatedFeeReverse)}
															fee={Number(calculatedFeeReverse)}
															withdrawMax={roundingNumber(currencyLimits?.maximum, code)}
															reference={values.reference}
															clientNote={values.instructions}
															files={fileList}
														/>
													)}
													{grossMode ? (
														<SuccessFiatWithdrawal
															open={openModalFiatSuccess}
															closeModal={closeModalHandler}
															amount={values.amount}
															bankNickname={values.bank}
															fee={Number(calculatedFee)}
															code={code}
															assetName={assetName}
														/>
													) : (
														<SuccessFiatWithdrawal
															open={openModalFiatSuccess}
															closeModal={closeModalHandler}
															amount={Number(values.net) + Number(calculatedFeeReverse)}
															bankNickname={values.bank}
															fee={Number(calculatedFeeReverse)}
															code={code}
															assetName={assetName}
														/>
													)}
													{grossMode ? (
														<ConfirmCryptoWithdrawal
															open={openModalConfirm}
															closeModal={closeModalConfirm}
															openSuccess={showModalSuccess}
															amount={values.amount}
															fee={Number(calculatedFee)}
															address={values.address}
															network={selectedWallet?.asset?.code || ''}
															code={code}
															assetName={assetName}
															asset_id={selectedWallet?.asset.id}
															chain_id={selectedWalletAddress?.chain_id}
															chain={selectedWalletAddress?.chain}
															address_id={selectedWalletAddress?.id}
															clearParentForm={closeModalHandler}
															reference={values.reference}
															clientNote={values.instructions}
															files={fileList}
														/>
													) : (
														<ConfirmCryptoWithdrawal
															open={openModalConfirm}
															closeModal={closeModalConfirm}
															openSuccess={showModalSuccess}
															amount={Number(values.net) + Number(calculatedFeeReverse)}
															fee={Number(calculatedFeeReverse)}
															address={values.address}
															network={selectedWallet?.asset?.code || ''}
															code={code}
															assetName={assetName}
															asset_id={selectedWallet?.asset.id}
															chain_id={selectedWalletAddress?.chain_id}
															chain={selectedWalletAddress?.chain}
															address_id={selectedWalletAddress?.id}
															clearParentForm={closeModalHandler}
															reference={values.reference}
															clientNote={values.instructions}
															files={fileList}
														/>
													)}
													{grossMode ? (
														<SuccessCryptoWithdrawal
															open={openModalSuccess}
															closeModal={handleCloseModalSuccess}
															amount={values.amount}
															fee={Number(calculatedFee)}
															address={values.address}
															network={selectedWallet?.asset?.code || ''}
															chain={selectedWalletAddress?.chain}
															code={code}
															assetName={assetName}
															clearParentForm={closeModalHandler}
															walletId={selectedWallet?.id}
														/>
													) : (
														<SuccessCryptoWithdrawal
															open={openModalSuccess}
															closeModal={handleCloseModalSuccess}
															amount={Number(values.net) + Number(calculatedFeeReverse)}
															fee={Number(calculatedFeeReverse)}
															address={values.address}
															network={selectedWallet?.asset?.code || ''}
															chain={selectedWalletAddress?.chain}
															code={code}
															assetName={assetName}
															clearParentForm={closeModalHandler}
															walletId={selectedWallet?.id}
														/>
													)}
												</>
											)}
										</Form>
									);
								}}
							</Formik>
						</div>
						{selectedWallet?.id && (
							<AddWalletAddress
								open={openModalAddAddress}
								closeModal={closeModalAddAddress}
								walletId={selectedWallet?.id}
								asset_id={selectedWallet?.asset.id}
								create
							/>
						)}
					</motion.div>
				</div>
			</section>
		</Auth>
	);
};

export default WithdrawFiatPage;
