import { FC } from 'react';
import { ILocalFilter } from '../types';

interface IProps {
	localFilter: ILocalFilter;
	changeFilter: (text: string) => void;
}

const TableHeader: FC<IProps> = ({ localFilter, changeFilter }) => {
	const filterActiveUp = (text: string) => {
		if (localFilter.state === 1 && localFilter.value === text) {
			return 'active';
		}
		return '';
	};
	const filterActiveDown = (text: string) => {
		if (localFilter.state === 2 && localFilter.value === text) {
			return 'active';
		}
		return '';
	};
	return (
		<div className="table-header">
			<div className="tr">
				<div className="td">
					<p>Timestamp</p>
				</div>
				<div className="td">
					<p>Sell</p>
				</div>
				<div className="td">
					<p>Buy</p>
				</div>
				<div className="td">
					<button
						onClick={() => {
							changeFilter('quantity');
						}}
						type="button"
						className="td-sorting-arrow-button"
					>
						Amount Sold
						<span className="td-sorting">
							<div className={`arrow-bottom ${filterActiveDown('quantity')}`} />
							<div className={`arrow-top ${filterActiveUp('quantity')}`} />
						</span>
					</button>
				</div>
				<div className="td">
					<button
						onClick={() => {
							changeFilter('amount');
						}}
						type="button"
						className="td-sorting-arrow-button"
					>
						Amount Received
						<span className="td-sorting">
							<div className={`arrow-bottom ${filterActiveDown('amount')}`} />
							<div className={`arrow-top ${filterActiveUp('amount')}`} />
						</span>
					</button>
				</div>
				<div className="td">
					<button
						onClick={() => {
							changeFilter('price_b2c2');
						}}
						type="button"
						className="td-sorting-arrow-button"
					>
						Exchange Rate
						<span className="td-sorting">
							<div className={`arrow-bottom ${filterActiveDown('price_b2c2')}`} />
							<div className={`arrow-top ${filterActiveUp('price_b2c2')}`} />
						</span>
					</button>
				</div>
				<div className="td">
					<button
						onClick={() => {
							changeFilter('fee');
						}}
						type="button"
						className="td-sorting-arrow-button"
					>
						Fee
						<span className="td-sorting">
							<div className={`arrow-bottom ${filterActiveDown('fee')}`} />
							<div className={`arrow-top ${filterActiveUp('fee')}`} />
						</span>
					</button>
				</div>
				<div className="td td--right">
					<p>Tx ID</p>
				</div>
			</div>
		</div>
	);
};

export default TableHeader;
