import React, { useState, FC, useRef } from 'react';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { createPasswordRequest } from 'redux/reducers/auth/reducer';
import { Field, Form, Formik } from 'formik';
import ReCAPTCHA from 'react-google-recaptcha';
import Input from 'ui/Formik/Input';
import { notificationContainer } from 'services/utils/notificationContainer';
import { notificationsMessagesError } from 'services/utils/notificationsMessages/notificationsMessagesError';
import { notificationsInfoFields } from 'services/utils/ipuntFields/ipuntFields';

interface IPasswordField {
	name: string;
	title: string;
	placeholder: string;
}

const PasswordField: FC<IPasswordField> = ({ name, title, placeholder }) => {
	const [isShowPass, setIsShowPass] = useState(false);

	const handlePassDisplay = (): void => {
		setIsShowPass(!isShowPass);
	};

	return (
		<div className="input">
			<p className="input__name">{title}</p>
			<div className="input-wrapper">
				<Field
					type={isShowPass ? 'text' : 'password'}
					placeholder={placeholder}
					name={name}
					required
					component={Input}
					ariaLabel="show-password"
					showPass={isShowPass}
					setIsShowPass={handlePassDisplay}
				/>

				<button
					onClick={() => {
						setIsShowPass(!isShowPass);
					}}
					type="button"
					className="show-pass"
				>
					<svg
						style={{ display: isShowPass ? 'none' : 'block' }}
						className="text-type"
						width="22"
						height="22"
						viewBox="0 0 22 22"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M13.3196 8.68125L8.68125 13.3196C8.08542 12.7237 7.71875 11.9079 7.71875 11.0004C7.71875 9.18542 9.18542 7.71875 11.0004 7.71875C11.9079 7.71875 12.7237 8.08542 13.3196 8.68125Z"
							stroke="#212C63"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M16.3358 5.29187C14.7317 4.08187 12.8983 3.42188 11.0008 3.42188C7.765 3.42188 4.74917 5.32854 2.65 8.62854C1.825 9.92104 1.825 12.0935 2.65 13.386C3.37417 14.5227 4.2175 15.5035 5.13417 16.2919"
							stroke="#212C63"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M7.71875 17.899C8.76375 18.339 9.87292 18.5773 11.0004 18.5773C14.2362 18.5773 17.2521 16.6706 19.3512 13.3706C20.1762 12.0781 20.1762 9.90562 19.3512 8.61312C19.0487 8.13646 18.7187 7.68729 18.3796 7.26562"
							stroke="#212C63"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M14.2178 11.6406C13.9795 12.9331 12.9253 13.9873 11.6328 14.2256"
							stroke="#212C63"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M8.67953 13.3203L1.83203 20.1678"
							stroke="#212C63"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M20.1678 1.83594L13.3203 8.68344"
							stroke="#212C63"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>

					<svg
						style={{ display: isShowPass ? 'block' : 'none' }}
						className="password-type"
						width="22"
						height="22"
						viewBox="0 0 22 22"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M14.2821 11.0004C14.2821 12.8154 12.8154 14.2821 11.0004 14.2821C9.18542 14.2821 7.71875 12.8154 7.71875 11.0004C7.71875 9.18542 9.18542 7.71875 11.0004 7.71875C12.8154 7.71875 14.2821 9.18542 14.2821 11.0004Z"
							stroke="#212C63"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M11.0008 18.5849C14.2367 18.5849 17.2525 16.6782 19.3517 13.3782C20.1767 12.0857 20.1767 9.91323 19.3517 8.62073C17.2525 5.32073 14.2367 3.41406 11.0008 3.41406C7.765 3.41406 4.74917 5.32073 2.65 8.62073C1.825 9.91323 1.825 12.0857 2.65 13.3782C4.74917 16.6782 7.765 18.5849 11.0008 18.5849Z"
							stroke="#212C63"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</button>
			</div>
		</div>
	);
};

interface IForm {
	password: string;
	confirmPassword: string;
}
const CreatePasswordForm = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const reCaptchaRef = useRef<ReCAPTCHA>(null);
	const siteKey = process.env.REACT_APP_GOOGLE_CAPTCHA;
	const [reCaptchaKey, setReCaptchaKey] = useState<string | null>(null);

	const onReCaptchaChange = (key: string | null) => {
		setReCaptchaKey(key);
	};

	const passwordChange = (obj: IForm) => {
		const token = history.location.search.slice(1).replace('/', '');
		if (!reCaptchaKey) {
			notificationContainer(notificationsMessagesError.the_captcha_field_is_required, 'error');
		} else {
			dispatch(
				createPasswordRequest({
					data: {
						password: obj.password,
						token,
					},
					history,
				}),
			);
		}
	};

	const initialValues = {
		password: '',
		confirmPassword: '',
	};
	const validationSchema = yup.object().shape({
		password: yup
			.string()
			.required(notificationsInfoFields.validationTexts.passwordRequired)
			.matches(
				/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[_#?!@$%^&*-]).{8,}$/,
				notificationsInfoFields.validationTexts.passwordMatches,
			)
			.max(25, 'Password is too long. Maximum 25 characters.'),
		confirmPassword: yup
			.string()
			.required(notificationsInfoFields.validationTexts.confirmPasswordRequired)
			.oneOf(
				[yup.ref('password'), null],
				notificationsInfoFields.validationTexts.confirmPasswordOneOf,
			)
			.max(25, 'Password is too long. Maximum 25 characters.'),
	});

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(values: IForm, { setSubmitting, resetForm }) => {
				passwordChange(values);
				setSubmitting(false);
				// resetForm();
			}}
			validateOnBlur
			enableReinitialize
		>
			{({ isSubmitting }) => (
				<Form className="form">
					<div className="login-form-item">
						<PasswordField name="password" title="Password" placeholder="Enter password" />
					</div>
					<div className="login-form-item mb-30">
						<PasswordField
							name="confirmPassword"
							title="Confirm Password"
							placeholder="Confirm password"
						/>
					</div>

					<div className="login-recaptcha">
						<ReCAPTCHA
							ref={reCaptchaRef}
							onChange={onReCaptchaChange}
							sitekey={siteKey || ''}
							hl="en"
						/>
					</div>

					<div className="login-form-item">
						<button
							disabled={isSubmitting}
							className="btn btn-primary btn--full"
							aria-label="form-submit"
							type="submit"
						>
							Confirm
						</button>
					</div>
				</Form>
			)}
		</Formik>
	);
};

export default CreatePasswordForm;
