export const notificationsInfoFields = {
	// login
	email: {
		required: 'Please enter your email.',
		invalid: 'Please enter a valid email address.',
		maxLength: `Email Address is too long. Maximum ${60} characters.`,
	},
	totp: {
		required: 'Please enter 2FA code.',
		invalid: 'Only numbers.',
		length: 'Please enter 6 character 2FA code.',
		matches: 'Only numbers',
	},
	password: {
		required: 'Please enter your password.',
		invalid:
			'Password must include: Minimum length of 8 characters; 1 uppercase letter; 1 lowercase letter; 1 number; and 1 special character.',
		maxLength: 'Max characters must be not more than 25.',
	},
	// deposit fiat
	currency: {
		required: 'Please enter your Other Currency.',
	},
	depositSummary: {
		required: 'Please enter Amount.',
		minZero: `Deposit summary should be more than 0.`,
		min: `Minimum amount`,
		max: `Maximum amount`,
	},
	amount: {
		required: 'Please enter Amount.',
		minZero: `Deposit summary should be more than 0.`,
		min: `Minimum amount`,
		max: `Maximum amount`,
	},
	// create password
	passwordValidation: {
		required: 'Please enter your password',
		matches:
			'Password must include: Minimum length of 8 characters; 1 uppercase letter; 1 lowercase letter; 1 number; and 1 special character.',
		maxLength: 'Max characters must be not more than 25.',
		passwordsMatch: 'Passwords must match.',
	},
	// create password form
	validationTexts: {
		passwordRequired: 'Please enter your password.',
		passwordMatches:
			'Password must include: Minimum length of 8 characters; 1 uppercase letter; 1 lowercase letter; 1 number; and 1 special character.',
		passwordMax: 'Max characters must be not more than 25.',
		confirmPasswordRequired: 'Passwords must match.',
		confirmPasswordOneOf: 'Passwords must match.',
	},
	// BankAccountForm // AddBankAccount
	validationMessages: {
		bank_nickname: {
			required: 'Please enter your Beneficiary Nickname.',
			min: `Beneficiary Nickname is too short. Minimum ${1} characters.`,
			max: `Beneficiary Nickname is too long. Maximum ${160} characters.`,
		},
		beneficiary_name: {
			required: 'Please enter your Beneficiary Name.',
			min: `Beneficiary Name is too short. Minimum ${1} characters.`,
			max: `Beneficiary Name is too long. Maximum ${60} characters.`,
		},
		beneficiary_address: {
			required: 'Please enter your Beneficiary Address.',
			min: `Beneficiary Address is too short. Minimum ${1} characters.`,
			max: `Beneficiary Address is too long. Maximum ${90} characters.`,
		},
		beneficiary_country: {
			required: 'Please, select your Beneficiary Country.',
		},
		bank_name: {
			required: 'Please enter your Bank Name.',
			min: `Bank Name is too short. Minimum ${1} characters.`,
			max: `Bank Name is too long. Maximum ${60} characters.`,
		},
		bank_address: {
			required: 'Please enter your Bank Address.',
			min: `Bank Address is too short. Minimum ${1} characters.`,
			max: `Bank Address is too long. Maximum ${95} characters.`,
		},
		bank_country: {
			required: 'Please, select your Bank Country.',
		},
		swift_bic: {
			required: 'Please enter your SWIFT / BIC.',
			min: `SWIFT / BIC is too short. Minimum ${8} characters.`,
			max: `SWIFT / BIC is too long. Maximum ${11} characters.`,
		},
		account_number: {
			required: 'Please enter your Account Number.',
			min: `Account Number is too short. Minimum ${5} characters.`,
			max: `Account Number is too long. Maximum ${34} characters.`,
		},
		note: {
			max: `Notes is too long. Maximum ${160} characters.`,
		},
	},
	// WalletAddressForm
	nickname: {
		required: 'Please enter your Wallet Nickname.',
		matches: 'Please enter valid nickname',
		min: `Wallet Nickname is too short. Minimum ${1} characters.`,
		max: `Wallet Nickname is too long. Maximum ${160} characters.`,
	},
	address: {
		required: 'Please enter your Wallet Address.',
		min: `Wallet Address is too short. Minimum ${34} characters.`,
		max: `Wallet Address is too long. Maximum ${90} characters.`,
		matches: 'Please enter valid address',
	},
	// reset password
	reason: {
		required: 'Please explain reason for password reset.',
		max: 'Max characters must be not more than 120.',
	},
	// to wa setting
	digit_code: {
		required: 'Please enter 2FA code.',
		matches: 'Please enter only numbers for the 2FA code.',
		length: 'Please enter a 6 digit 2FA code.',
	},
	// add wallet address
	// nickname: {
	// 	required: 'Please enter your Wallet Nickname.',
	// 	matches: 'Please enter valid nickname',
	// },
	// currency: {
	// 	required: 'Please enter Currency.',
	// },
	// address: {
	// 	required: 'Please enter your Wallet Address.',
	// 	matches: 'Please enter valid address',
	// },
	network: {
		required: 'Please enter Network.',
	},
	bank: {
		required: 'Please enter your Bank Account.',
	},
	wallet_address: {
		required: 'Please enter your Wallet Address.',
	},
	fullName: {
		min: `Too short. Minimum ${1} characters.`,
		max: `Full Name is too long. Maximum ${50} characters.`,
		required: 'Please enter your full name.',
	},
	phone: {
		required: 'Please enter your phone.',
		matches: 'Incorrect number format',
		max: `Phone Number is too long. Maximum ${20} characters.`,
	},
	message: {
		required: 'Please enter your Message.',
		min: 'Too Short.',
		max: `Message is too long. Maximum ${255} characters.`,
	},
	// withdraw crypto page
	required: 'Please fill in this field.',
	wallet: {
		required: 'Please enter your Other Wallet.',
	},
	reference: {
		max: `Reference is too long. Maximum ${255} characters.`,
	},
	instructions: {
		max: `Notes / Instructions is too long. Maximum ${255} characters.`,
	},
};
