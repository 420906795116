import React, { FC, useCallback } from 'react';
// import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
// import { popUpOpen } from 'redux/reducers/popUp/reducer';
// import { changeAsset } from 'redux/reducers/trade/reducer';
import { IWalletItem } from 'redux/reducers/wallets/types';
import { roundingNumber } from 'services/utils/roundingNumber';
import { Link } from 'react-router-dom';

const WalletsTableItem: FC<IWalletItem> = ({ id, asset, balance, frozen_balance, balance_eur }) => {
	const history = useHistory();
	// const dispatch = useDispatch();
	const type = asset.type === 'fiat' ? 'fiat' : 'crypto';

	const handleClickToDepositPage = useCallback(() => {
		// history.push(`deposit-${type}`);
		history.push(`deposit`);
		localStorage.asset = JSON.stringify({ asset_id: asset.id });
	}, [asset.id, history]);

	const handleClickToFiatPage = useCallback(() => {
		// history.push(`withdraw-${type}`);
		history.push(`withdrawal`);
		localStorage.asset = JSON.stringify({ asset_id: id });
	}, [history, id]);

	const handleClickToTrade = useCallback(() => {
		history.push('/trade');
		localStorage.asset = JSON.stringify({
			asset_id: asset.id,
			asset_code: asset.code,
			type: asset.type,
		});
	}, [asset.id, asset.code, asset.type, history]);

	/* const handleClickToTrade = () => {
		dispatch(popUpOpen('goToTrade'));
		dispatch(changeAsset({ type: 'from', asset_id: String(asset.id), asset_code: asset.code }));
		const fromToAsset =
			asset.type === 'fiat'
				? { asset_id: '1', asset_code: 'BTC' }
				: { asset_id: '10', asset_code: 'EUR' };
		dispatch(changeAsset({ type: 'to', ...fromToAsset }));
	}; */

	return (
		<>
			{Number(balance) === 0 &&
			Number(frozen_balance) === 0 &&
			!asset.exchangeable &&
			!asset.withdrawable &&
			!asset.depositable ? null : (
				<div className="tr">
					<div className="td">
						<div className="td-hidden-name">{asset.name}</div>
						<Link
							to={`/history/statement?currency=${asset.code.toLowerCase()}`}
							className="coin coin--type3 coin--gap-10"
						>
							<span className="coin__icon">
								<img
									// eslint-disable-next-line @typescript-eslint/no-var-requires,global-require,import/no-dynamic-require
									src={`/img/currencies/${asset.code.toLowerCase()}.svg`}
									alt={asset.code}
								/>
							</span>
							<span className="coin__text coin__text--fw-400 coin__text---upper">
								{asset.code} <br />
								<span className="coin__text-more coin__text-more--type2">{asset.name}</span>
							</span>
						</Link>
					</div>
					<div className="td">
						<div className="td-hidden-name">Balance</div>
						<div className="td__wrap">
							<div className="td__text">
								{roundingNumber(Number(balance) + Number(frozen_balance), asset.code)}
							</div>
						</div>
					</div>
					<div className="td">
						<div className="td-hidden-name">Available</div>
						<div className="td__wrap">
							<div className="td__text">{roundingNumber(balance, asset.code)}</div>
						</div>
					</div>
					<div className="td">
						<div className="td-hidden-name">Pending</div>
						<div className="td__wrap">
							<div className="td__text">{roundingNumber(frozen_balance, asset.code)}</div>
						</div>
					</div>
					<div className="td">
						<div className="td-hidden-name">EUR Value</div>
						<div className="td__wrap">
							<div className="td__text">
								{roundingNumber(
									Number((Number(balance) + Number(frozen_balance)).toFixed(6).slice(0, -1)) > 0
										? balance_eur
										: 0,
									'EUR',
								)}
							</div>
							<div className="td__more">EUR</div>
						</div>
					</div>
					<div className="td">
						<div className="flex flex-e flex--gap-8">
							{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
							<button
								type="button"
								className="btn btn--icon btn--icon-trade"
								onClick={handleClickToTrade}
								disabled={!asset.exchangeable}
								title="Trade"
							/>
							{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
							<button
								type="button"
								className="btn btn--icon btn--icon-deposit"
								onClick={handleClickToDepositPage}
								disabled={!asset.depositable}
								title="Deposit"
							/>
							{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
							<button
								type="button"
								className="btn btn--icon btn--icon-withdraw"
								onClick={handleClickToFiatPage}
								disabled={!asset.withdrawable}
								title="Withdraw"
							/>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default WalletsTableItem;
