import { FC } from 'react';
import Popup from 'reactjs-popup';
import { useHistory } from 'react-router';
import { ROUTES } from 'routes/constants';
import { useDispatch } from 'react-redux';
import { IPopUp } from '../types';
import { roundingNumber } from '../../../services/utils/roundingNumber';
import { getWalletsRequest } from '../../../redux/reducers/wallets/reducer';
import { toFixedNumber } from '../../../services/utils/toFixedNumber';

interface IWithdrawPropsData {
	data: {
		amount: string;
		assetName: string;
		bankNickname: string;
		fee: string;
		indicatedAmount: number | string;
		code: string;
		walletId: string | number;
	};
}

const WithdrawConfirm: FC<IPopUp & IWithdrawPropsData> = ({ open, closeModal, data }) => {
	const history = useHistory();
	const dispatch = useDispatch();

	const handleGoToClick = () => {
		history.push(`${ROUTES.WITHDRAWALS_HISTORY}/withdrawal-fiat`);
		closeModal();
	};

	// const handleCloseButton = () => {
	// 	history.push(ROUTES.WALLETS.INDEX);
	// 	closeModal();
	// };

	const handleClose = () => {
		dispatch(getWalletsRequest());
		if (data?.walletId) {
			localStorage.asset = JSON.stringify({ asset_id: data?.walletId });
		}
		closeModal();
	};
	return (
		<Popup open={open} closeOnDocumentClick onClose={handleClose}>
			<div className="popup popup--width-480">
				{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
				<button type="button" className="popup__close-btn" onClick={handleClose} />
				<div className="popup-header popup-header--mb-20">
					<p className="popup-header__title">Withdrawal Request Submitted</p>
				</div>
				<div className="popup-icon popup-icon--type2">
					<svg
						width="58"
						height="58"
						viewBox="0 0 58 58"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M29.0013 49.8307C40.4596 49.8307 49.8346 40.4557 49.8346 28.9974C49.8346 17.5391 40.4596 8.16406 29.0013 8.16406C17.543 8.16406 8.16797 17.5391 8.16797 28.9974C8.16797 40.4557 17.543 49.8307 29.0013 49.8307Z"
							fill="#007F00"
							stroke="#007F00"
							strokeOpacity="0.06"
							strokeWidth="16"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path d="M21.0469 28.6171L26.3437 33.9139L36.956 23.3203" fill="#007F00" />
						<path
							d="M21.0469 28.6171L26.3437 33.9139L36.956 23.3203"
							stroke="white"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</div>
				<div className="popup-body">
					<div className="successful-info">
						<div className="successful-info__item">
							<p>Currency:</p>
							<span>
								{data.code} ({data.assetName})
							</span>
						</div>
						<div className="successful-info__item">
							<p>Bank Account:</p>
							<span>{data.bankNickname}</span>
						</div>
						<div className="successful-info__item">
							<p>Amount Requested:</p>
							<span>
								{roundingNumber(data.indicatedAmount, data.code)} {data.code}
							</span>
						</div>
						<div className="successful-info__item">
							<p>Withdrawal Fee:</p>
							<span>
								{roundingNumber(data.fee, data.code)} {data.code}
							</span>
						</div>
						<div className="successful-info__item">
							<p>Withdrawal Amount:</p>
							<span>
								{toFixedNumber(Number(data.amount) - Number(data.fee), data.code, true)} {data.code}
							</span>
						</div>
					</div>
				</div>
				<div className="popup-footer">
					<div className="popup-submit popup-submit--type2">
						<button onClick={handleGoToClick} type="button" className="btn btn-primary btn--full">
							Withdrawal History
						</button>
						{/* <button onClick={handleCloseButton} type="button" className="btn btn-danger btn--full">
							Close
						</button> */}
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default WithdrawConfirm;
