import React, { FC, useEffect, useMemo, useState } from 'react';
import Select from '../index';
import { IBankSelectItem, ICurrencySelect, ICurrencySelectCustomList } from './types';
import { ICurrencySelectItem } from '../CurrencySelect/types';

const CurrencySelectCustomInput: FC<IBankSelectItem> = ({ bank_nickname, img_path }) => (
	<span className="coin coin--type3" style={{ display: bank_nickname ? '' : 'none' }}>
		{img_path && (
			<span className="coin__icon">
				<img src={img_path} alt={bank_nickname} />
			</span>
		)}
		<span className="coin__text">{bank_nickname}</span>
	</span>
);

const CurrencySelectCustomList: FC<ICurrencySelectCustomList> = ({ arr, onChange }) => (
	<ul>
		{arr &&
			arr.map((el) => (
				<li key={el.id}>
					<button type="button" onClick={() => onChange(el)}>
						<span className="coin coin--type3">
							{el.img_path && (
								<span className="coin__icon">
									<img src={el.img_path} alt={el.bank_name} />
								</span>
							)}
							<span className="coin__text">{el.bank_nickname}</span>
						</span>
					</button>
				</li>
			))}
	</ul>
);
const BankSelect: FC<ICurrencySelect> = ({
	touched,
	setTouched,
	activeValue,
	arr,
	resetCustomSelect,
	setResetCustomSelect,
	onChange,
	...props
}) => {
	const initActiveCurrency = useMemo(
		() => ({
			bank_nickname: '',
		}),
		[],
	);

	const [activeCurrency, setActiveCurrency] = useState<IBankSelectItem>(initActiveCurrency);
	const [searchBank, setSearchBank] = useState<ICurrencySelectItem[]>([]);

	useEffect(() => {
		if (arr) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			setSearchBank(arr);
		}
		// console.log(arr);
	}, [arr]);

	useEffect(() => {
		if (resetCustomSelect) {
			setActiveCurrency(initActiveCurrency);
			!!setResetCustomSelect && setResetCustomSelect(false);
		}
	}, [initActiveCurrency, resetCustomSelect, setResetCustomSelect]);

	const onChangeHandler = (el: IBankSelectItem) => {
		setActiveCurrency(el);
		!!onChange && onChange(el);
	};

	const customList = {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		list: <CurrencySelectCustomList arr={searchBank} onChange={onChangeHandler} />,
		activeElement: activeCurrency.bank_nickname,
	};

	useEffect(() => {
		if (arr?.length === 1) {
			onChangeHandler(arr[0]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [arr, activeCurrency]);

	return (
		<Select
			{...props}
			arr={arr}
			setTouched={setTouched}
			touched={touched && !activeValue?.value}
			searchField
			setSearchBank={setSearchBank}
			customInput={<CurrencySelectCustomInput {...activeCurrency} />}
			customList={customList}
		/>
	);
};

export default BankSelect;
