import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router';
import { roundingNumber } from '../../../services/utils/roundingNumber';
import { IWalletItem } from './type';

const TableItem: FC<IWalletItem> = ({ id, asset, balance }) => {
	const history = useHistory();

	return (
		<>
			{Number(balance) === 0 &&
			!asset.withdrawable &&
			!asset.depositable &&
			!asset.exchangeable ? null : (
				<div className="trade-wallet-balances-table__tr" key={id}>
					<div className="trade-wallet-balances-table__td">
						<div className="trade-wallet-balances-table__coin">
							<img src={`/img/currencies/${asset.code.toLowerCase()}.svg`} alt={asset.name} />
							<div className="trade-wallet-balances-table__text">
								{asset.code}
								<span>{asset.name}</span>
							</div>
						</div>
					</div>
					<div className="trade-wallet-balances-table__td">
						<p>{roundingNumber(balance, asset.code)}</p>
					</div>
					<div className="trade-wallet-balances-table__td">
						<div className="trade-wallet-balances-table__actions">
							{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
							<button
								type="button"
								className="btn btn--icon btn--icon-deposit"
								onClick={() => {
									// history.push(asset.type === 'token' ? `deposit-crypto` : `deposit-fiat`);
									history.push(`deposit`);
									localStorage.asset = JSON.stringify({ asset_id: asset.id });
								}}
								disabled={!asset.depositable}
								title="Deposit"
							/>
							{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
							<button
								type="button"
								className="btn btn--icon btn--icon-withdraw"
								onClick={() => {
									// history.push(asset.type === 'token' ? `withdraw-crypto` : `withdraw-fiat`);
									history.push(`withdrawal`);
									localStorage.asset = JSON.stringify({ asset_id: id });
								}}
								disabled={!asset.withdrawable}
								title="Withdraw"
							/>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default TableItem;
