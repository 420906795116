import { IStoreState } from '../../types';
import { IDetailedViewStore } from './types';

export const getDetailedView = (state: IStoreState): IDetailedViewStore => state.detailedView;

export const getDetailedViewLoading = (state: IStoreState) => {
	return getDetailedView(state).loading;
};

export const getTradeDetails = (state: IStoreState) => {
	return getDetailedView(state).tradeDetails;
};
