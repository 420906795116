import { FormikValues } from 'formik';
import { FC, useEffect } from 'react';
import FormControl from 'ui/Formik/FormConrol';
import { IAdminInput } from './types';

// ==========================================:
const Input: FC<IAdminInput & FormikValues> = (props) => {
	const {
		ariaLabel,
		type,
		placeholder,
		field,
		className,
		onKeyUp,
		onBlur,
		onChange,
		disabled,
		inputMode,
		readonly,
		setValue,
	} = props;

	const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const phoneNumber = event.target.value.replace(/[^0-9]/g, '');
		!!setValue && setValue('phone', phoneNumber, true);
	};

	return (
		<FormControl ariaLabel={ariaLabel} {...props}>
			{type !== 'textarea' ? (
				<input
					className={`input-item ${String(className)}`}
					{...field}
					type={type}
					placeholder={placeholder}
					onKeyUp={onKeyUp}
					onBlur={onBlur || field.onBlur}
					disabled={disabled}
					inputMode={inputMode || null}
					onWheel={(e) => e.currentTarget.blur()}
					readOnly={!!readonly}
					onChange={field.name === 'phone' ? handlePhoneChange : onChange || field.onChange}
				/>
			) : (
				<textarea
					className="input-item textarea_item"
					{...field}
					type={type}
					placeholder={placeholder}
					onKeyUp={onKeyUp}
					inputMode={inputMode || null}
					disabled={disabled}
					onChange={onChange || field.onChange}
					onBlur={onBlur || field.onBlur}
				/>
			)}
		</FormControl>
	);
};

export default Input;
