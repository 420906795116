import React, { FC, useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

import { capitalize, shortNotation } from 'services/utils/strings';
import { notificationContainer } from 'services/utils/notificationContainer';
import { CopyIcon } from 'assets/inline-svg';
import { statusClassNames, statusNames } from 'components/TransactionHistory/utils';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useRouteMatch } from 'react-router';
import { ICryptoTableBodyProps } from '../types';
import { roundingNumber } from '../../../../services/utils/roundingNumber';
import { toFixedNumber } from '../../../../services/utils/toFixedNumber';

const TableBody: FC<ICryptoTableBodyProps> = ({ itemList, localFilter }) => {
	const { path } = useRouteMatch();
	const [itemFilter, setItemFilter] = useState(itemList);

	useEffect(() => {
		/* if (localFilter.state === 0) {
			setItemFilter(itemList);
		}

		if (localFilter.state === 1) {
			const max = itemList?.slice().sort((a, b) => {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				return a[localFilter.value] - b[localFilter.value];
			});
			setItemFilter(max);
		}
		if (localFilter.state === 2) {
			const min = itemList?.slice().sort((a, b) => {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				return b[localFilter.value] - a[localFilter.value];
			});
			setItemFilter(min);
		} */

		setItemFilter(itemList);
	}, [itemList, localFilter]);
	const formatDateTime = (sourceDate: string) => {
		const date = new Date(sourceDate);

		const yyyy = date.getFullYear();
		const mo = String(date.getMonth() + 1).padStart(2, '0');
		const dd = String(date.getDate()).padStart(2, '0');
		const hh = String(date.getHours()).padStart(2, '0');
		const mm = String(date.getMinutes()).padStart(2, '0');
		const ss = String(date.getSeconds()).padStart(2, '0');

		return [`${yyyy}-${mo}-${dd}`, `${hh}:${mm}:${ss}`];
	};

	const handleCopy = () => {
		notificationContainer('Сopied to clipboard', 'info');
	};

	return (
		<div className="table-body">
			{itemFilter?.length ? (
				itemFilter?.map((item) => {
					const [date, time] = formatDateTime(item.created_at);
					return (
						<div key={item.id} className="tr">
							<div className="td td--nowrap">
								<div className="td-hidden-name">Timestamp</div>
								<div className="td__wrap">
									{item.type === 'deposit' ? (
										<>
											<div className="td__text">
												{moment.utc(item.created_at).format('YYYY-MM-DD')}
											</div>
											<div className="td__more">
												{moment.utc(item.created_at).format('HH:mm:ss')}
											</div>
										</>
									) : (
										<>
											<div className="td__text">{date}</div>
											<div className="td__more">{time}</div>
										</>
									)}
								</div>
							</div>
							{/* <div className="td td--nowrap">
								<div className="td-hidden-name">Type</div>
								<p>{capitalize(item.type)}</p>
							</div> */}
							<div className="td">
								<div className="td-hidden-name">Currency</div>
								<div className="td__wrap">
									<div className="td__text">{item.asset.code.toUpperCase()}</div>
									<div className="td__more">{item.asset.name}</div>
								</div>
							</div>
							{/* <div className="td">
								<div className="td-hidden-name">Network</div>
								<p>
									{item.chain.name} <br />
									<span className="td-more">
										{item.chain.name} Smart Chain ({item.chain.code})
									</span>
								</p>
							</div> */}
							<div className="td">
								<div className="td-hidden-name">Gross</div>
								<div className="td__wrap">
									<div className="td__text">{roundingNumber(item.gross_fee, item.asset.code)}</div>
									<div className="td__more">{item.asset.code.toUpperCase()}</div>
								</div>
							</div>
							<div className="td">
								<div className="td-hidden-name">Fee</div>
								<div className="td__wrap">
									<div className="td__text">{roundingNumber(item.fee, item.asset.code)}</div>
									<div className="td__more">{item.asset.code.toUpperCase()}</div>
								</div>
							</div>
							<div className="td">
								<div className="td-hidden-name">Net</div>
								<div className="td__wrap">
									<div className="td__text">
										{toFixedNumber(item.net_fee, item.asset.code, true)}
									</div>
									<div className="td__more">{item.asset.code.toUpperCase()}</div>
								</div>
							</div>
							<div className="td">
								<div className="td-hidden-name">Destination</div>
								<div className="td__wrap">
									<div className="copy-button">
										{item.destination && shortNotation(item.destination, 4, 4)}
										{item.destination && (
											<CopyToClipboard text={item.destination} onCopy={handleCopy}>
												<button type="button">
													<CopyIcon />
												</button>
											</CopyToClipboard>
										)}
										{!item.destination && item.address && shortNotation(item.address.address, 4, 4)}
										{!item.destination && item.address && (
											<CopyToClipboard text={item.address.address} onCopy={handleCopy}>
												<button type="button">
													<CopyIcon />
												</button>
											</CopyToClipboard>
										)}
										{!item.destination && !item.address && '-'}
									</div>
								</div>
							</div>
							<div className="td">
								<div className="td-hidden-name">Tx hash</div>
								<div className="td__wrap">
									<div className="copy-button">
										{item.tx_hash && shortNotation(item.tx_hash, 4, 4)}
										{item.tx_hash && (
											<CopyToClipboard text={item.tx_hash} onCopy={handleCopy}>
												<button type="button">
													<CopyIcon />
												</button>
											</CopyToClipboard>
										)}
										{!item.tx_hash && '-'}
									</div>
									{/* {item.tx_hash && (
										<a href="#" className="td__link">
											View Explorer
										</a>
									)} */}
								</div>
							</div>
							<div className="td">
								<div className="td-hidden-name">Tx ID</div>
								<div className="td__wrap">
									<div className="td__text">
										<Link
											to={{
												pathname: `${path}/${item.id}`,
												state:
													path === '/history/withdrawal-crypto'
														? { fromWithdrawalHistoryCrypto: true, txId: item.id }
														: { fromDepositHistoryCrypto: true, txId: item.id },
											}}
										>
											{item.id}
										</Link>
									</div>
								</div>
								{/* <p className="copy-button"> */}
								{/*	<p>{item.tx_id !== null ? shortNotation(item.tx_id, 2, 2) : '-'}</p> */}
								{/*	{item.tx_id && ( */}
								{/*		<CopyToClipboard text={item.tx_id} onCopy={handleCopy}> */}
								{/*			<button type="button"> */}
								{/*				<CopyIcon /> */}
								{/*			</button> */}
								{/*		</CopyToClipboard> */}
								{/*	)} */}
								{/* </p> */}
							</div>
							<div className="td">
								<div className="td-hidden-name">Status</div>
								<div className="td__wrap">
									<div className={`td__status ${statusClassNames[item.status]}`}>
										{statusNames[item.status]}
									</div>
								</div>
							</div>
						</div>
					);
				})
			) : (
				<div className="td text--center">Transactions is empty</div>
			)}
		</div>
	);
};

export default TableBody;
