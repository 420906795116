import { Redirect, Route, Switch, useRouteMatch } from 'react-router';

import StatementTable from './Table';
import DetailedView from '../DetailedView/DetailedView';

const StatementTab = () => {
	const { path: matchPath } = useRouteMatch();

	return (
		<div className="wallet-operations">
			<Switch>
				<Route exact path={`${matchPath}`} component={StatementTable} />
				<Route path={`${matchPath}/:id`} component={DetailedView} />
				<Redirect to={`${matchPath}`} />
			</Switch>
		</div>
	);
};

export default StatementTab;
