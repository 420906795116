import { CloseFileIcon } from 'assets/inline-svg';
import uploadImage from 'layouts-elements/DropFileCustom/config/ImageConfig';
import React, { useState, DragEvent, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';

interface IInputFile {
	fileList: any;
	setFileList: React.Dispatch<any>;
}

const InputFile: React.FC<IInputFile> = ({ fileList, setFileList }) => {
	const [drag, setDrag] = useState(false);
	const handleDragStart = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		setDrag(true);
	};
	const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		setDrag(false);
	};
	const handleOnDrop = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		const updatedList = [...fileList, ...e.dataTransfer.files];
		setFileList(updatedList);
		setDrag(false);
	};

	const onFileDrop = (e: any) => {
		const newFile = e.target.files[0];
		if (newFile) {
			const updatedList = [...fileList, newFile];
			setFileList(updatedList);
		}
	};

	const fileRemove = (file: any) => {
		const updatedList = [...fileList];
		updatedList.splice(fileList?.indexOf(file), 1);
		setFileList(updatedList);
	};
	return (
		<>
			<div
				className={`input-file${drag ? ' active' : ''}`}
				onDragStart={handleDragStart}
				onDragLeave={handleDragLeave}
				onDragOver={handleDragStart}
				onDrop={handleOnDrop}
			>
				<label className="input-file__button">
					<input type="file" value="" onChange={onFileDrop} className="hidden" />
					<span className="verification-upload__button-content">
						<svg
							width="58"
							height="59"
							viewBox="0 0 58 59"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M29.0013 50.3385C40.4596 50.3385 49.8346 40.9635 49.8346 29.5052C49.8346 18.0469 40.4596 8.67188 29.0013 8.67188C17.543 8.67188 8.16797 18.0469 8.16797 29.5052C8.16797 40.9635 17.543 50.3385 29.0013 50.3385Z"
								fill="#0071AB"
								stroke="#007F00"
								strokeOpacity="0.06"
								strokeWidth="16"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M31.959 27.4375C34.359 27.6442 35.339 28.8775 35.339 31.5775V31.6642C35.339 34.6442 34.1457 35.8375 31.1657 35.8375H26.8257C23.8457 35.8375 22.6523 34.6442 22.6523 31.6642V31.5775C22.6523 28.8975 23.619 27.6642 25.979 27.4442"
								stroke="white"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M29 22.8359V31.4226"
								stroke="white"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M31.2323 29.9375L28.999 32.1708L26.7656 29.9375"
								stroke="white"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
						<span>
							<b>Click to upload</b> or drag and drop files
						</span>
					</span>
				</label>
			</div>
			{fileList.length > 0 ? (
				<div className="input-file-preview">
					{fileList.map((item: any) => (
						<div key={uuidv4()} className="input-file-preview__item">
							<img src={uploadImage[item.type.split('/')[1]]} alt="" />
							<span className="input-file-preview__name" title={item.name}>
								{item.name}
							</span>
							<span className="drop-file-preview__item__del" onClick={() => fileRemove(item)}>
								<CloseFileIcon />
							</span>
						</div>
					))}
				</div>
			) : null}
		</>
	);
};

export default InputFile;
