import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import {
	createCryptoDetailsRequest,
	createCryptoDetailsSuccess,
	createFiatDetailsRequest,
	createFiatDetailsSuccess,
	createTradeDetailsRequest,
	createTradeDetailsSuccess,
	getTradeDetailsRequest,
	getTradeDetailsSuccess,
	hideLoading,
} from './reducer';
import { api } from '../../../services';
import {
	ICreateCryptoDetailsRequest,
	ICreateFiatDetailsRequest,
	ICreateTradeDetailsRequest,
} from '../../../services/api/detailedView/types';
import { IGetTransactionHistoryRequestPayload } from '../transactions/types';
import {
	getCryptoHistoryRequest,
	getFiatHistoryRequest,
	getTradeHistoryRequest,
} from '../transactions/reducer';
import { responseErrors } from '../../../services/http/responseErrors';
import { notificationContainer } from '../../../services/utils/notificationContainer';
import { IDetailedViewTradeDetails } from './types';

function* getTradeDetailsWorker({ payload }: PayloadAction<number>) {
	try {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const response = yield call(api.detailedView.getTradeDetails, payload);
		yield put(getTradeDetailsSuccess(response));
	} catch (error) {
		yield put(hideLoading());
		console.log(error);
		if (axios.isAxiosError(error)) {
			notificationContainer(
				'Something went wrong, please try again later.',
				'user_blocked',
				'Error',
			);
		}
	}
}

function* createTradeDetailsWorker({ payload }: PayloadAction<ICreateTradeDetailsRequest>) {
	try {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const response = yield call(api.detailedView.postTradeDetails, payload.body);
		// const params: IGetTransactionHistoryRequestPayload = {
		// 	apiParams: {
		// 		current_page: 1,
		// 		per_page: 100000000,
		// 		id: Number(payload.id),
		// 	},
		// };
		// yield put(getTradeHistoryRequest(params));
		// yield put(createTradeDetailsSuccess(response));
		yield put(getTradeDetailsRequest(Number(payload.id)));
	} catch (error) {
		yield put(hideLoading());
		console.log(error);
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* createFiatDetailsWorker({ payload }: PayloadAction<ICreateFiatDetailsRequest>) {
	try {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const response = yield call(api.detailedView.postFiatDetails, payload.body);
		const params: IGetTransactionHistoryRequestPayload = {
			apiParams: {
				current_page: 1,
				per_page: 100000000,
				id: Number(payload.id),
			},
		};
		yield put(getFiatHistoryRequest(params));
		yield put(createFiatDetailsSuccess());
	} catch (error) {
		yield put(hideLoading());
		console.log(error);
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* createCryptoDetailsWorker({ payload }: PayloadAction<ICreateCryptoDetailsRequest>) {
	try {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const response = yield call(api.detailedView.postCryptoDetails, payload.body);
		const params: IGetTransactionHistoryRequestPayload = {
			apiParams: {
				current_page: 1,
				per_page: 100000000,
				id: Number(payload.id),
			},
		};
		yield put(getCryptoHistoryRequest(params));
		yield put(createCryptoDetailsSuccess());
	} catch (error) {
		yield put(hideLoading());
		console.log(error);
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

export function* detailedViewSaga() {
	yield takeEvery(getTradeDetailsRequest, getTradeDetailsWorker);
	yield takeEvery(createTradeDetailsRequest, createTradeDetailsWorker);
	yield takeEvery(createFiatDetailsRequest, createFiatDetailsWorker);
	yield takeEvery(createCryptoDetailsRequest, createCryptoDetailsWorker);
}
