import { api } from 'services';
import axios from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import {
	ILimits,
	ITopPairItem,
	ITradeExchangeRatePayload,
	ITradeExchangeRateResponse,
	ITradeQuoteRequestPayload,
	ITradeQuoteResponse,
	ITradeResponse,
	TApiGetCryptoHistoryResponse,
	TApiGetFiatHistoryResponse,
	TApiGetTradeHistoryResponse,
	IFeesByAssetResponse,
	ICryptoWithdrawRequestWithSuccessFunc,
	IFiatWithdrawRequestWithSuccessFunc,
	IFiatDepositGetFile,
	IFiatDepositGetFileResponse,
	TLedgerResponse,
	IApiGetLedgerTransactionParams,
} from 'services/api/transactions/types';
import { responseErrors } from 'services/http/responseErrors';

import {
	getCryptoHistoryError,
	getCryptoHistoryRequest,
	getCryptoHistorySuccess,
	getFiatHistoryError,
	getFiatHistoryRequest,
	getFiatHistorySuccess,
	getTopPairRequest,
	getTopPairSuccess,
	getTopPairError,
	getTradeExchangeRateRequest,
	getTradeExchangeRateSuccess,
	getTradeHistoryRequest,
	getTradeHistorySuccess,
	getTradeQuoteRequest,
	getTradeQuoteSuccess,
	getTradeExchangeRateModalSuccess,
	makeTradeRequest,
	makeTradeSuccess,
	createFiatWithdrawRequest,
	createCryptoWithdrawRequest,
	getFeesByAssetRequest,
	getFeesByAssetSuccess,
	getFeesByAssetError,
	getUserLimitsRequest,
	getUserLimitsSuccess,
	getUserLimitsError,
	postFiatDepositInvoiceCreateRequest,
	postFiatDepositInvoiceCreateSuccess,
	getInvoiceData,
	getTradeExchangeRateTitleRequest,
	getTradeExchangeRateTitleSuccess,
	makeTradeError,
	getLedgerTransactionsRequest,
	getLedgerTransactionsSuccess,
	disableLedgerLoading,
} from './reducer';
import { IApiInvoiceData, TGetTransactionHistoryRequestPayloadUnsafe } from './types';
import { popUpOpen, setPopUpData } from '../popUp/reducer';
import { notificationContainer } from '../../../services/utils/notificationContainer';
import { updatePopUpState } from '../trade/reducer';
import { getErrorStatus } from '../errors/selectors';

function* getCryptoHistoryWorker({
	payload = {},
}: PayloadAction<TGetTransactionHistoryRequestPayloadUnsafe>) {
	const { apiParams, onFinally } = payload;
	try {
		const data: TApiGetCryptoHistoryResponse = yield call(
			api.transactions.getCryptoHistory,
			apiParams,
		);

		yield put(getCryptoHistorySuccess(data));
	} catch (error) {
		yield put(getCryptoHistoryError());
	} finally {
		onFinally?.();
	}
}

function* getFiatHistoryWorker({
	payload = {},
}: PayloadAction<TGetTransactionHistoryRequestPayloadUnsafe>) {
	const { apiParams, onFinally } = payload;
	try {
		const data: TApiGetFiatHistoryResponse = yield call(api.transactions.getFiatHistory, apiParams);

		yield put(getFiatHistorySuccess(data));
	} catch (error) {
		yield put(getFiatHistoryError());
	} finally {
		onFinally?.();
	}
}
function* getTradeHistoryWorker({
	payload = {},
}: PayloadAction<TGetTransactionHistoryRequestPayloadUnsafe>) {
	const { apiParams, onFinally } = payload;
	try {
		const data: TApiGetTradeHistoryResponse = yield call(api.transactions.getTradeHistory, {
			status: 'completed',
			...apiParams,
		});
		yield put(getTradeHistorySuccess(data));
		// eslint-disable-next-line no-empty
	} catch (error) {
		// console.log(error);
	}
}
function* getTradeQuoteWorker({ payload }: PayloadAction<ITradeQuoteRequestPayload>) {
	// console.log(`Всегда должна быть криптой ${payload.quantity}`);
	try {
		// const response: ITradeQuoteResponse = yield call(api.transactions.getTradeQuote, payload);
		// const responseModal: ITradeExchangeRateResponse = yield call(
		// 	api.transactions.getTradeExchangeRate,
		// 	payload,
		// );
		// yield put(getTradeQuoteSuccess(response));
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		yield put(getTradeQuoteSuccess());
		// yield put(getTradeExchangeRateModalSuccess(responseModal));
	} catch {
		// put(hideLoading());
	}
}
function* getTradeExchangeRateWorker({ payload }: PayloadAction<ITradeExchangeRatePayload>) {
	try {
		yield put(showLoading());
		const response: ITradeExchangeRateResponse = yield call(
			api.transactions.getTradeExchangeRate,
			payload,
		);
		yield put(getTradeExchangeRateSuccess(response));
		// eslint-disable-next-line no-empty
	} catch (error) {
		// console.log(error);
	}
}
function* getTradeExchangeRateTitleWorker({ payload }: PayloadAction<ITradeExchangeRatePayload>) {
	try {
		yield put(showLoading());
		const response: ITradeExchangeRateResponse = yield call(
			api.transactions.getTradeExchangeRate,
			payload,
		);
		yield put(getTradeExchangeRateTitleSuccess(response));
		// eslint-disable-next-line no-empty
	} catch (error) {
		// console.log(error);
	}
}

function* makeTradeWorker({ payload }: PayloadAction<ITradeQuoteRequestPayload>) {
	// console.log(`Sell ${payload.quantity}`);
	// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
	// console.log(`Крипта ${payload?.crypto_quantity}`);
	try {
		yield put(showLoading());
		const response: ITradeResponse = yield call(
			api.transactions.makeTrade,
			// remove popupData from payload
			(({ popupData, ...o }) => o)(payload),
		);
		yield put(makeTradeSuccess(response));
		yield put(popUpOpen('successTrade'));
		yield put(updatePopUpState(false));
		yield put(setPopUpData({ data: payload.popupData }));
		yield put(getTradeHistoryRequest({ apiParams: { per_page: 5 } }));
	} catch (error) {
		yield put(makeTradeError());
		yield put(updatePopUpState(false));
		const status: number[] = yield select(getErrorStatus);
		if (!status.includes(500)) {
			yield put(popUpOpen('errorTrade'));
		}
	} finally {
		yield put(hideLoading());
	}
}
function* getTopPairWorker() {
	try {
		yield put(showLoading());
		const response: ITopPairItem[] = yield call(api.transactions.getTopPair);
		yield put(getTopPairSuccess(response));
	} catch (error) {
		yield put(getTopPairError());
	} finally {
		yield put(hideLoading());
	}
}

function* createFiatWithdrawWorker({
	payload,
}: PayloadAction<IFiatWithdrawRequestWithSuccessFunc>) {
	try {
		yield call(api.transactions.createFiatWithdraw, payload.body);

		yield put(setPopUpData({ data: payload.data }));
		yield put(popUpOpen('withdrawConfirmed'));
		yield call(payload.clearParentForm);
	} catch (error) {
		if (
			axios.isAxiosError(error) &&
			payload.setErrors &&
			error?.response?.data.errors[0] ===
				'secret_key_is_too_short_must_be_at_least16_base32_characters'
		) {
			yield call(payload.setErrors, { totp: 'Please enter valid Authentication Code.' });
		}
		if (
			axios.isAxiosError(error) &&
			payload.setErrors &&
			error?.response?.data.errors[0].includes('out_of_range_value')
		) {
			notificationContainer(
				// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
				`Maximum withdrawal amount is ${payload.withdrawMax}. Please contact support for additional information.`,
				'error',
			);
		}
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		// console.log(error?.response);
	}
}

function* createCryptoWithdrawWorker({
	payload,
}: PayloadAction<ICryptoWithdrawRequestWithSuccessFunc>) {
	try {
		yield call(api.transactions.createCryptoWithdraw, payload.body);
		yield call(() => {
			if (payload.openSuccess) {
				payload?.openSuccess();
			}
		});
	} catch (error) {
		if (
			axios.isAxiosError(error) &&
			payload.setErrors &&
			error?.response?.data.errors[0] ===
				'secret_key_is_too_short_must_be_at_least16_base32_characters'
		) {
			yield call(payload.setErrors, { totp: 'Please enter valid Authentication Code.' });
		}
	} finally {
		yield put(hideLoading());
	}
}

function* getFeesByAssetRequestWorker({ payload }: PayloadAction<number | string>) {
	try {
		yield put(showLoading());
		const response: IFeesByAssetResponse = yield call(api.transactions.getFeesByAsset, payload);
		yield put(getFeesByAssetSuccess(response));
	} catch (error) {
		yield put(getFeesByAssetError());
	} finally {
		yield put(hideLoading());
	}
}

function* getUserLimitsRequestWorker({ payload }: PayloadAction<number>) {
	try {
		yield put(showLoading());
		const response: ILimits = yield call(api.transactions.getLimitsByAsset, payload);
		yield put(getUserLimitsSuccess(response));
	} catch (error) {
		yield put(getUserLimitsError());
	} finally {
		yield put(hideLoading());
	}
}

function* postFiatDepositInvoiceCreateWorker({ payload }: PayloadAction<IFiatDepositGetFile>) {
	const { body, onFinally } = payload;
	let hasError = false;
	try {
		yield put(showLoading());
		const response: IFiatDepositGetFileResponse = yield call(
			api.transactions.postFiatDepositInvoiceCreate,
			body,
		);
		const invoice: IApiInvoiceData = yield call(api.transactions.getInvoiceData, response.url_show);
		yield put(getInvoiceData(invoice));
		yield put(postFiatDepositInvoiceCreateSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			hasError = true;
		}
		yield put(getUserLimitsError());
	} finally {
		yield put(hideLoading());
		onFinally?.(hasError);
	}
}

function* getLedgerTransactionsWorker({ payload }: PayloadAction<IApiGetLedgerTransactionParams>) {
	try {
		const data: TLedgerResponse = yield call(api.transactions.getLedgerTransactions, {
			...payload,
		});

		yield put(getLedgerTransactionsSuccess(data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(disableLedgerLoading());
	}
}

export function* transactionsSaga() {
	yield takeLatest(getCryptoHistoryRequest, getCryptoHistoryWorker);
	yield takeLatest(getFiatHistoryRequest, getFiatHistoryWorker);
	yield takeLatest(getTradeHistoryRequest, getTradeHistoryWorker);
	yield takeEvery(getTradeQuoteRequest, getTradeQuoteWorker);
	// yield takeEvery(getTradeExchangeRateRequest, getTradeExchangeRateWorker);
	yield takeEvery(getTradeExchangeRateTitleRequest, getTradeExchangeRateTitleWorker);
	yield takeEvery(makeTradeRequest, makeTradeWorker);
	yield takeEvery(getTopPairRequest, getTopPairWorker);
	yield takeEvery(createFiatWithdrawRequest.type, createFiatWithdrawWorker);
	yield takeEvery(createCryptoWithdrawRequest.type, createCryptoWithdrawWorker);
	yield takeEvery(getFeesByAssetRequest.type, getFeesByAssetRequestWorker);
	yield takeEvery(postFiatDepositInvoiceCreateRequest.type, postFiatDepositInvoiceCreateWorker);
	yield takeEvery(getUserLimitsRequest.type, getUserLimitsRequestWorker);
	yield takeLatest(getLedgerTransactionsRequest, getLedgerTransactionsWorker);
}
