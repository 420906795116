import React, { FC, useEffect, useState } from 'react';
import Dashboard from 'layouts/Dashboard';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import two1 from 'assets/dist/img/2fa-app1.png';
import two2 from 'assets/dist/img/2fa-app2.png';
import InputTotp from 'ui/Formik/InputTotp';
import { generateCodeForTwoFaRequest } from 'redux/reducers/auth/reducer';
import { getTwoFaData } from 'redux/reducers/twoFa/selectors';
import { twoCodeUpdateRequest } from 'redux/reducers/twoFa/reducer';
import { Helmet } from 'react-helmet';
import { notificationsInfoFields } from '../../../services/utils/ipuntFields/ipuntFields';
import loginBannerImage from '../../../assets/dist/img/login-banner@2x-min.png';

// ==========================================:

const validationSchema = yup.object().shape({
	digit_code: yup
		.string()
		.required(notificationsInfoFields.totp.required)
		.matches(/\d/, notificationsInfoFields.digit_code.matches)
		.length(6, notificationsInfoFields.digit_code.length),
});

const TwoFaCreatePage: FC = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const token = location.search.slice(1);
	const faData = useSelector(getTwoFaData);
	const [bannerPositionLeft, setBannerPositionLeft] = useState(1);

	useEffect(() => {
		const bodyRect = document.body.getBoundingClientRect();
		const loginBanner = document.getElementById('login_banner');
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const loginBannerRect = loginBanner.getBoundingClientRect();
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const rect = loginBannerRect.left - bodyRect.left;
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		setBannerPositionLeft(rect);
	}, []);

	useEffect(() => {
		if (token) {
			dispatch(generateCodeForTwoFaRequest({ token }));
		}
	}, [dispatch, token]);

	return (
		<Dashboard title="create 2fa">
			<>
				<Helmet>
					<body className="login-page" />
					<style type="text/css">{`
					:root {
							--login-banner-left: ${bannerPositionLeft}px;
					}
			`}</style>
				</Helmet>
				<section className="login-section">
					<div className="container">
						<div className="login">
							<div className="login-form login-form--w-580">
								<div className="login-form__title text-left">
									<p className="text-left">Install Google Authenticator</p>
								</div>

								<Formik
									initialValues={{ digit_code: '' }}
									validationSchema={validationSchema}
									onSubmit={(values, { setSubmitting, resetForm }) => {
										dispatch(twoCodeUpdateRequest({ totp: values.digit_code, token }));
										setSubmitting(false);
										// resetForm();
									}}
								>
									{({ isSubmitting }) => (
										<Form className="form">
											<div className="settings-2fa settings-2fa--reg-setup">
												<div className="settings-2fa__title">
													<div className="settings-2fa__title-number">
														<p>1</p>
													</div>
													<p>Download 2FA App</p>
												</div>
												<div className="settings-2fa__text">
													<p>
														Please download and install an authentication application (we recommend
														Google Authenticator or Authy) on your mobile device. This app will
														generate a one-time 6-digit access code which is required to access your
														account.
													</p>
												</div>
												<div className="settings-2fa-app flex flex-m">
													<a
														href="https://apps.apple.com/ru/app/google-authenticator/id388497605"
														target="_blank"
														className="settings-2fa-app__item"
														rel="noreferrer"
													>
														<img src={two1} alt="" />
													</a>
													<a
														href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
														target="_blank"
														className="settings-2fa-app__item"
														rel="noreferrer"
													>
														<img src={two2} alt="" />
													</a>
												</div>

												<div className="settings-2fa__divider" />

												<div className="settings-2fa__title">
													<div className="settings-2fa__title-number">
														<p>2</p>
													</div>
													<p>Save 2FA Backup Key and Scan QR Code</p>
												</div>
												<div className="settings-2fa__text">
													<p>
														Please backup your 2FA recovery key by saving to offline storage, write
														down on a piece of paper or print a copy and store it safety. If your
														mobile device gets lost, stolen or erased you will need this key to
														recover your 2FA access.
													</p>
												</div>
												<div className="settings-2fa-qr-code">
													<div className="settings-2fa-qr-code__photo">
														<img src={faData?.QR_Image} alt="" />
													</div>
													<div className="settings-2fa-qr-code__info">
														<div className="input">
															<div className="input__name">
																<p>Backup / Secret Key</p>
															</div>
															<div className="input-wrapper">
																<input
																	className="input-item input-item--main-color2"
																	type="text"
																	placeholder="Enter Key"
																	readOnly
																	value={faData?.secret}
																/>
															</div>
															<div className="input__notification input__notification--type3">
																<p>
																	IMPORTANT: Please make sure you have made a backup of your
																	recovery key.
																</p>
															</div>
														</div>
													</div>
												</div>

												<div className="settings-2fa__divider" />

												<div className="settings-2fa__title">
													<div className="settings-2fa__title-number">
														<p>3</p>
													</div>
													<p>Confirm 2FA Activation</p>
												</div>
												<div className="settings-2fa-item mb-30">
													<div className="input">
														<div className="input-wrapper">
															<Field
																title="2FA Code from Authenticator App"
																type="text"
																placeholder="Please enter the 6-digit code"
																name="digit_code"
																required
																component={InputTotp}
															/>
														</div>
													</div>
												</div>
												<div>
													<button
														disabled={isSubmitting}
														className="btn btn-primary btn--full"
														aria-label="form-submit"
														type="submit"
													>
														Confirm
													</button>
												</div>
											</div>
										</Form>
									)}
								</Formik>
							</div>
						</div>

						<div className="login-banner" id="login_banner">
							<img src={loginBannerImage} alt="" />
							<p className="login-banner__text">
								Multi-currency accounts, foreign exchange and
								<br /> payments that streamline your global operations
							</p>
						</div>
					</div>
				</section>
			</>
		</Dashboard>
	);
};

export default TwoFaCreatePage;
