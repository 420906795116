import { Redirect, Route, Switch, useRouteMatch } from 'react-router';

import { useLocation } from 'react-router-dom';
import HistoryHeader from './HistoryHeader/HistoryHeader';
import Trade from './Trade/Trade';
import DetailedView from '../DetailedView/DetailedView';

const TransactionsTrade = () => {
	const { path: matchPath } = useRouteMatch();
	const { state } = useLocation();

	return (
		<div className="wallet-operations">
			{!state && <HistoryHeader />}
			<Switch>
				<Route exact path={`${matchPath}`} component={Trade} />
				<Route path={`${matchPath}/:id`} component={DetailedView} />
				{/* <Route exact path={`${matchPath}/crypto`} component={Crypto} /> */}
				{/* <Route exact path={`${matchPath}/fiat`} component={Fiat} /> */}

				<Redirect to={`${matchPath}`} />
			</Switch>
		</div>
	);
};

export default TransactionsTrade;
