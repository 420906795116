import React, { useEffect, useState } from 'react';
import { IBankAccount } from 'redux/reducers/bankAccounts/types';
import { useSelector } from 'react-redux';
import { getFiatCurrencyData } from '../../../../redux/reducers/currency/selectors';
import { ICurrencyDataItem } from '../../../../redux/reducers/currency/types';

const TableBodyBankAccount: React.FC<IBankAccount> = ({
	beneficiary_name,
	beneficiary_country,
	beneficiary_address,
	bank_address,
	bank_name,
	bank_country,
	swift_bic,
	account_number,
	note,
	bank_currency,
}) => {
	const fiatCurrencyData = useSelector(getFiatCurrencyData);
	const [bankCurrency, setBankCurrency] = useState<ICurrencyDataItem[]>();

	useEffect(() => {
		if (fiatCurrencyData && bank_currency) {
			setBankCurrency(fiatCurrencyData.filter((el) => el.id === bank_currency));
		}
	}, [bank_currency, fiatCurrencyData]);

	return (
		<div className="payment-methods-body">
			<div className="payment-methods-list">
				<div className="payment-methods-item">
					<p>Currency</p>
					{bankCurrency && bankCurrency.length > 0 ? (
						<>
							<span className="coin coin--type8">
								<span className="coin__icon">
									<img
										src={`/img/currencies/${bankCurrency[0].code.toLowerCase()}.svg`}
										alt={bankCurrency[0].code}
									/>
								</span>
								<span className="coin__text coin__text---upper">
									{bankCurrency[0].code.toUpperCase()}
								</span>
								<span className="coin__text-more">{bankCurrency[0].name}</span>
							</span>
						</>
					) : (
						<span>-</span>
					)}
				</div>
				<div className="payment-methods-item">
					<p>Beneficiary Name</p>
					<span>{beneficiary_name}</span>
				</div>
				<div className="payment-methods-item">
					<p>Beneficiary Bank</p>
					<span>{bank_name}</span>
				</div>
				<div className="payment-methods-item payment-methods-item--text-right">
					<p>Account Number</p>
					<span>{account_number}</span>
				</div>
				{/* <div className="payment-methods-item">
				<p>Beneficiary Address</p>
				<span>{beneficiary_address}</span>
			</div>
			<div className="payment-methods-item">
				<p>Beneficiary Country</p>
				<span>{beneficiary_country}</span>
			</div>
			<div className="payment-methods-item">
				<p>Bank Address</p>
				<span>{bank_address}</span>
			</div>
			<div className="payment-methods-item">
				<p>Bank Country</p>
				<span>
					{' '}
					{bank_country === ' Caribbean Netherlands' ? '🇧🇶 Caribbean Netherlands' : bank_country}
				</span>
			</div>
			<div className="payment-methods-item">
				<p>SWIFT / BIC</p>
				<span>{swift_bic}</span>
			</div> */}
			</div>
			{/* <div className="payment-methods-list payment-methods-list--type2">
			<div className="payment-methods-item">
				<p>Notes</p>
				<span>{note}</span>
			</div>
		</div> */}
		</div>
	);
};

export default TableBodyBankAccount;
