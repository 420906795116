import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router';
import { useEffect, useState } from 'react';

import HistoryHeader from './HistoryHeader/HistoryHeader';
import Crypto from './Crypto/Crypto';
import Fiat from './Fiat/Fiat';
import CryptoDeposit from './Crypto/CryptoDeposit';
import FiatDeposit from './Fiat/FiatDeposit';
import DetailedView from '../DetailedView/DetailedView';

const DepositsWithdrawals = () => {
	const { path: matchPath } = useRouteMatch();
	const [title, setTitle] = useState('');
	const { pathname, state } = useLocation();

	useEffect(() => {
		// console.log(matchPath);
		if (pathname === '/history/deposit-fiat' || pathname === '/history/deposit-crypto') {
			setTitle('Deposit History');
			return;
		}
		if (pathname === '/history/withdrawal-fiat' || pathname === '/history/withdrawal-crypto') {
			setTitle('Withdrawal History');
			return;
		}
		setTitle('Deposits & Withdrawals');
	}, [pathname]);

	return (
		<div className="wallet-operations">
			{!state && <HistoryHeader title={title} />}
			<Switch>
				{/* <Route exact path={`${matchPath}/crypto`} component={Crypto} />
				<Route exact path={`${matchPath}/fiat`} component={Fiat} />

				<Redirect to={`${matchPath}/crypto`} /> */}
				<Route exact path="/history/deposit-crypto" component={CryptoDeposit} />
				<Route exact path="/history/deposit-fiat" component={FiatDeposit} />
				<Route exact path="/history/withdrawal-crypto" component={Crypto} />
				<Route exact path="/history/withdrawal-fiat" component={Fiat} />
				<Route path={`${matchPath}/:id`} component={DetailedView} />

				<Redirect to={`${matchPath}/deposit-crypto`} />
			</Switch>
		</div>
	);
};

export default DepositsWithdrawals;
