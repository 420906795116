import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getUserData } from 'redux/reducers/auth/selectors';
import { getUserRequest } from 'redux/reducers/auth/reducer';
import { getCryptoHistoryList } from '../../../redux/reducers/transactions/selectors';
import { IGetTransactionHistoryRequestPayload } from '../../../redux/reducers/transactions/types';
import { getCryptoHistoryRequest } from '../../../redux/reducers/transactions/reducer';
import { roundingNumber } from '../../../services/utils/roundingNumber';
import { toFixedNumber } from '../../../services/utils/toFixedNumber';
import { statusNames } from '../../TransactionHistory/utils';
import DetailedViewCryptoForm from '../DetailedViewForm/DetailedViewCryptoForm';
import DefaultFields from '../DefaultFields/DefaultFields';

export interface IDepositHistoryCryptoDetails {
	txId: string | number;
}

interface ICustomInput {
	label: string;
	value: string | number | undefined;
	type: string;
	disabled: boolean;
}

const CustomInput: FC<ICustomInput> = ({ label, value, type, disabled }) => {
	return (
		<div className="input">
			<label>
				<p className="input__name">{label}</p>
				<div className="input-wrapper">
					<input type={type} className="input-item" value={value} disabled={disabled} />
				</div>
			</label>
		</div>
	);
};

const DepositHistoryCryptoDetails: FC<IDepositHistoryCryptoDetails> = ({ txId }) => {
	const dispatch = useDispatch();
	const currentItemData = useSelector(getCryptoHistoryList);

	const userData = useSelector(getUserData);

	useEffect(() => {
		if (txId) {
			const params: IGetTransactionHistoryRequestPayload = {
				apiParams: {
					current_page: 1,
					per_page: 100000000,
					id: Number(txId),
				},
			};
			dispatch(getCryptoHistoryRequest(params));
		}
	}, [dispatch, txId]);

	useEffect(() => {
		dispatch(getUserRequest());
	}, [dispatch]);

	return (
		<>
			{currentItemData?.length && userData ? (
				currentItemData?.map((item) => {
					return (
						<div className="detailed-view" key={item.id}>
							<div className="input-wrap--col-2">
								<DefaultFields item={item} userData={userData} />

								<CustomInput
									label="Currency"
									// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
									value={`${item.asset.code.toUpperCase()} ${item.asset.name}`}
									type="text"
									disabled
								/>
								<CustomInput
									label="Gross"
									value={`${roundingNumber(
										item.gross_fee,
										item.asset.code,
										// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
									)} ${item.asset.code.toUpperCase()}`}
									type="text"
									disabled
								/>

								<CustomInput
									label="Fee"
									value={`${roundingNumber(
										item.fee,
										item.asset.code,
										// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
									)} ${item.asset.code.toUpperCase()}`}
									type="text"
									disabled
								/>
								<CustomInput
									label="Net"
									value={`${toFixedNumber(
										item.net_fee,
										item.asset.code,
										true,
										// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
									)} ${item.asset.code.toUpperCase()}`}
									type="text"
									disabled
								/>

								{/* {item.status === 'admin_pending' ||
									(item.status === 'pending' && (
										<CustomInput
											label="Type"
											// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
											value={`${item.type}`}
											type="text"
											disabled
										/>
									))} */}
								<CustomInput
									label="Destination"
									value={
										// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
										item.description || item.destination || item.address.address || '-'
									}
									type="text"
									disabled
								/>
								<CustomInput
									label="Tx hash"
									// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
									value={`${item.tx_hash ? item.tx_hash : '-'}`}
									type="text"
									disabled
								/>
								{/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
								<CustomInput label="Tx ID" value={`${item.id}`} type="text" disabled />
								<CustomInput
									label="Status"
									value={`${statusNames[item.status]}`}
									type="text"
									disabled
								/>
								<CustomInput
									label="Description"
									value={
										// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
										item.description || item.destination || item.address.address || '-'
									}
									type="text"
									disabled
								/>
								<CustomInput
									label="Reference"
									// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
									value={`${item.reference ? item.reference : item.tx_hash || '-'}`}
									type="text"
									disabled
								/>
							</div>
							<DetailedViewCryptoForm item={item} />
						</div>
					);
				})
			) : (
				<div>No data</div>
			)}
		</>
	);
};

export default DepositHistoryCryptoDetails;
