import { http } from '../../http';
import { endpoint } from '../../endpoint';
import { IDetailedViewApi } from './types';

export const detailedView: IDetailedViewApi = {
	getTradeDetails: (params) =>
		http.get(endpoint.detailedView.GET_TRADE_DETAILS(params)).then(({ data }) => data),
	postTradeDetails: (payload) =>
		http.post(endpoint.detailedView.UPDATE_TRADE_DETAILS, payload).then(({ data }) => data),

	getFiatDetails: (params) =>
		http.get(endpoint.detailedView.GET_FIAT_DETAILS(params)).then(({ data }) => data),
	postFiatDetails: (payload) =>
		http.post(endpoint.detailedView.UPDATE_FIAT_DETAILS, payload).then(({ data }) => data),

	getCryptoDetails: (params) =>
		http.get(endpoint.detailedView.GET_CRYPTO_DETAILS(params)).then(({ data }) => data),
	postCryptoDetails: (payload) =>
		http.post(endpoint.detailedView.UPDATE_CRYPTO_DETAILS, payload).then(({ data }) => data),
};
