/* eslint-disable @typescript-eslint/no-explicit-any */
import fileDefault from '../img/file-blank-solid-240.png';
import fileCSS from '../img/file-css-solid-240.png';
import filePdf from '../img/vscode-icons_file-type-pdf2.svg';
import filePng from '../img/file-png-solid-240.png';
import fileJpg from '../img/file-jpg-solid-240.png';

const ImageConfig: any = {
	default: fileDefault,
	pdf: filePdf,
	png: filePng,
	css: fileCSS,
	jpeg: fileJpg,
};
export default ImageConfig;

export const handleUploadIcon = (type: string | undefined) => {
	switch (type) {
		case 'pdf':
			return filePdf;
		case 'png':
			return filePng;
		case 'jpeg':
			return fileJpg;

		case 'css':
			return fileCSS;
		default:
			return fileDefault;
	}
};
