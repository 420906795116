import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IMessageDownloadFilesRequestPayload,
	IMessageRequestPayload,
	IMessageStore,
	IMessageWithPagination,
} from './types';

export const initialState: IMessageStore = {
	messages: null,
	loading: false,
};

const messages = createSlice({
	name: '@@messages',
	initialState,
	reducers: {
		getMessagesRequest: (state, action: PayloadAction<IMessageRequestPayload>) => {
			const newState = state;
			newState.loading = true;
		},
		getMessagesSuccess: (state, action: PayloadAction<IMessageWithPagination>) => {
			const newState = state;
			newState.messages = action.payload;
			newState.loading = false;
		},

		downloadFilesSuccess: (state) => {
			const newState = state;
		},
		downloadFilesRequest: (state, action: PayloadAction<IMessageDownloadFilesRequestPayload>) => {
			const newState = state;
		},
	},
});

export default messages.reducer;

export const {
	getMessagesRequest,
	getMessagesSuccess,
	downloadFilesRequest,
	downloadFilesSuccess,
} = messages.actions;
